import React, { useEffect, useRef, useState } from "react";
import { useOutsideClick } from "../../../hooks/useOutsideClick";

const ModalSelectCountries = ({
  data,
  optionDefault,
  formik,
  field,
  dropListUp,
  defaultOption,
  onSelect,
}) => {
  const newData = Array.isArray(data) ? data : [data];
  const [isDropdownVisible, setDropdownVisibility] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(newData);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (typeof defaultOption === "object" && defaultOption !== null) {
      handleOptionSelect(defaultOption);
    }
  }, []);

  const toggleDropdown = () => {
    setDropdownVisibility(!isDropdownVisible);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option?.name);
    if (formik && field) {
      formik.setFieldValue(field, option);
    } else if (onSelect) {
      onSelect(option);
    }
    setDropdownVisibility(false);
  };

  const handleInputChange = (event) => {
    const searchText = event.currentTarget.value.toLowerCase();
    setInputValue(searchText);
    const filtered = newData.filter((obj) =>
      obj.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const handleClickOutside = (event) => {
    setDropdownVisibility(false);
  };
  useOutsideClick(dropdownRef, handleClickOutside);

  return (
    <div
      className="relative rounded-xl text-[#8597A8] w-full"
      ref={dropdownRef}
    >
      {isDropdownVisible ? (
        <div className="text-[#8597A8] bg-white flex justify-between font-medium border=[#B8BBC2] border-[1.3px] rounded-[8px] text-sm px-5 py-2.5 w-full">
          <input
            className="block w-full"
            style={{ outline: "none" }}
            autoFocus
            value={inputValue}
            onChange={handleInputChange}
          />
          <button onClick={toggleDropdown}>
            <svg
              className={`w-2.5 h-2.5 ms-3 ${
                isDropdownVisible
                  ? "transition duration-500 ease-in-out transform rotate-180"
                  : "transition duration-500 ease-in-out transform rotate-0"
              }`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="#8597A8"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button>
        </div>
      ) : (
        <button
          id="dropdownPeriodButton"
          data-dropdown-toggle="dropdownPeriod"
          className="flex justify-between text-[#8597A8] w-full bg-white font-medium border=[#B8BBC2] border-[1.3px] rounded-[8px] text-sm px-5 py-2.5 text-center items-center whitespace-nowrap overflow-hidden text-ellipsis"
          type="button"
          onClick={toggleDropdown}
        >
          <div className="truncate">{selectedOption || optionDefault}</div>
          <svg
            className={`w-2.5 h-2.5 ms-3 ${
              isDropdownVisible
                ? "transition duration-500 ease-in-out transform rotate-180"
                : "transition duration-500 ease-in-out transform rotate-0"
            }`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="#8597A8"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
      )}

      <div
        id="dropdownPeriod"
        className={`absolute z-10 mt-2 bg-white divide-y divide-gray-100 rounded-[8px] shadow w-full ${
          isDropdownVisible ? "" : "hidden"
        }`}
        style={dropListUp && { bottom: "50px" }}
      >
        <ul
          className="py-2 text-sm text-[#8597A8] max-h-[200px] w-full overflow-y-auto"
          aria-labelledby="dropdownDefaultButton"
        >
          {filteredOptions.map((el, i) => (
            <li key={`${el + i}`}>
              <a
                href="#"
                className="block px-4 py-2 hover:bg-gray-100 whitespace-nowrap overflow-hidden text-ellipsis"
                onClick={() => handleOptionSelect(el)}
              >
                {el.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ModalSelectCountries;
