import React, { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import moment from "moment";
import { useUserStore } from "../../storesZustand/useUserStore";
import { isAuthenticated } from "../../components/auth-gaurd";
import Layout from "../../components/layout/Layout";
import BreadcrumbsComponent from "../../components/UI/breadcrumbs";
import BurgerOpen from "../../components/burger-open";
import { useViewDasboardStore } from "../../storesZustand/useViewDasboardStore";
import api from "../../utils/api";

const UsageContent = () => {
  const [user] = useUserStore((state) => [state.user]);
  const [setIsOpen] = useViewDasboardStore((state) => [state.setIsOpen]);
  const [deviceIds, setDeviceIds] = useState("");
  const [fromDate, setFromDate] = useState(
    moment().subtract(1, "hour").format("YYYY-MM-DDTHH:mm")
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [frequency, setFrequency] = useState("DAILY");
  const [timezone, setTimezone] = useState("Etc/UTC");
  const [response, setResponse] = useState("");

  // RawData state
  const [rawDeviceIds, setRawDeviceIds] = useState("");
  const [rawFromDate, setRawFromDate] = useState(
    moment().subtract(10, "years").format("YYYY-MM-DDTHH:mm")
  );
  const [rawToDate, setRawToDate] = useState(
    moment().format("YYYY-MM-DDTHH:mm")
  );
  const [rawDataInput, setRawDataInput] = useState("");
  const [rawDataOutput, setRawDataOutput] = useState("");
  const [isDelete, setIsDelete] = useState(false);

  // Define breadcrumbs array
  const breadCrumbsArr = [
    { name: "Home", url: "/" },
    { name: "Usage", url: "/usage" },
  ];

  const toggleDrawer = () => {
    setIsOpen(true);
  };

  const handleGetData = async () => {
    try {
      // Convert dates to epoch time
      const fromEpoch = moment(fromDate).unix();
      const toEpoch = moment(toDate).unix();

      // Format device IDs (split by newline and trim)
      const deviceIdList = deviceIds
        .split("\n")
        .map((id) => id.trim())
        .filter((id) => id);

      const queryParams = new URLSearchParams({
        device_ids: deviceIdList.join(","),
        from_dt: fromEpoch,
        to_dt: toEpoch,
        freq: frequency,
        tz: timezone,
      });

      const response = await api.get(`/device/usage-time?${queryParams}`);
      const data = response.data;

      // Format the data for display
      const formattedData = Object.entries(data.data).reduce(
        (acc, [deviceId, usageData]) => {
          acc[deviceId] = Object.entries(usageData).reduce(
            (usageAcc, [date, value]) => {
              usageAcc[date] = value;
              return usageAcc;
            },
            {}
          );
          return acc;
        },
        {}
      );

      setResponse(JSON.stringify(formattedData, null, 2));
    } catch (error) {
      setResponse(`Error: ${error.message}`);
    }
  };

  const handleRawData = async () => {
    try {
      if (isDelete) {
        const deviceIdList = rawDeviceIds
          .split("\n")
          .map((id) => id.trim())
          .filter((id) => id);

        if (deviceIdList.length === 0) {
          throw new Error("Please enter at least one device ID");
        }

        const fromDt = Math.floor(moment(rawFromDate).unix());
        const toDt = Math.floor(moment(rawToDate).unix());

        if (fromDt >= toDt) {
          throw new Error("From date must be before To date");
        }

        // Handle Delete request
        const response = await api.delete("/stats/raw-data", {
          params: {
            device_ids: deviceIdList.join(","),
            from_dt: fromDt,
            to_dt: toDt,
          },
        });

        if (response.data.success) {
          setRawDataOutput(
            `Successfully deleted ${
              response.data.message.deleted_count
            } record${response.data.message.deleted_count === 1 ? "" : "s"}`
          );
        } else {
          setRawDataOutput(response.data.message || "Delete operation failed");
        }
      } else {
        // Handle Post request
        let dataToPost;
        try {
          dataToPost = JSON.parse(rawDataInput);
        } catch (error) {
          throw new Error("Invalid JSON format in Data to Post field");
        }

        // Validate data format
        if (!Array.isArray(dataToPost)) {
          throw new Error("Data must be an array of records");
        }

        // Process each record - no date conversion needed as timestamps are already in Unix format
        const processedData = dataToPost.map((record) => ({
          device_id: record.device_id,
          start_sess: record.start_sess,
          recorded_at: record.recorded_at,
          tot_relay: parseInt(record.tot_relay),
        }));

        const response = await api.post("/stats/raw-data", processedData);
        if (response.data.success) {
          setRawDataOutput("Success");
        } else {
          setRawDataOutput(response.data.message || "Post operation failed");
        }
      }
    } catch (error) {
      if (error.response?.data?.message) {
        setRawDataOutput(error.response.data.message);
      } else {
        setRawDataOutput(`Error: ${error.message}`);
      }
    }
  };

  const allowedEmails = [
    "martin.clowry@halcyongroup.ie",
    "john.coleman@halcyongroup.ie",
    "conordisigns@gmail.com",
  ];

  const showRawData = allowedEmails.includes(user?.email);

  return (
    <div className="w-full h-full sm:p-10 p-4 pt-[20px] sm:pt-[20px] xl:pt-[25px] sm:pr-[120px]">
      <div className="flex items-center mb-4 xl:hidden">
        <button
          onClick={toggleDrawer}
          className="p-2 mr-4 rounded-md hover:bg-gray-200"
          aria-label="Toggle navigation menu"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
        <BreadcrumbsComponent breadCrumbsArr={breadCrumbsArr} />
      </div>

      <div className="hidden xl:block mb-4">
        <BreadcrumbsComponent breadCrumbsArr={breadCrumbsArr} />
      </div>

      <div
        className={`flex flex-col lg:flex-row gap-8 p-4 max-w-[1600px] mx-auto min-h-[calc(100vh-200px)] ${
          !showRawData ? "justify-center" : ""
        }`}
      >
        <div
          className={`flex-1 bg-white rounded-lg shadow-sm p-6 min-w-0 ${
            !showRawData ? "lg:max-w-[600px] mx-auto" : ""
          }`}
        >
          <h1 className="text-2xl font-semibold mb-6 text-[#32383F]">
            Usage Time
          </h1>

          <div className="mb-5">
            <label className="block mb-2 font-medium text-[#8597A8] text-sm">
              Device IDs (one per line):
            </label>
            <textarea
              value={deviceIds}
              onChange={(e) => setDeviceIds(e.target.value)}
              placeholder="Enter device IDs, one per line"
              className="w-full min-h-[100px] p-3 border border-[#E5E7EB] rounded-md font-mono resize-y text-sm text-[#32383F] bg-white focus:outline-none focus:border-[#3B82F6] focus:ring-2 focus:ring-[#3B82F6]/10"
            />
          </div>

          <div className="mb-5">
            <label className="block mb-2 font-medium text-[#8597A8] text-sm">
              From:
            </label>
            <input
              type="datetime-local"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              className="w-full p-3 border border-[#E5E7EB] rounded-md text-sm text-[#32383F] bg-white focus:outline-none focus:border-[#3B82F6] focus:ring-2 focus:ring-[#3B82F6]/10"
            />
          </div>

          <div className="mb-5">
            <label className="block mb-2 font-medium text-[#8597A8] text-sm">
              To:
            </label>
            <input
              type="datetime-local"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              className="w-full p-3 border border-[#E5E7EB] rounded-md text-sm text-[#32383F] bg-white focus:outline-none focus:border-[#3B82F6] focus:ring-2 focus:ring-[#3B82F6]/10"
            />
          </div>

          <div className="mb-5">
            <label className="block mb-2 font-medium text-[#8597A8] text-sm">
              Frequency:
            </label>
            <select
              value={frequency}
              onChange={(e) => setFrequency(e.target.value)}
              className="w-full p-3 border border-[#E5E7EB] rounded-md text-sm text-[#32383F] bg-white focus:outline-none focus:border-[#3B82F6] focus:ring-2 focus:ring-[#3B82F6]/10"
            >
              <option value="DAILY">Daily</option>
              <option value="WEEKLY">Weekly</option>
              <option value="MONTHLY">Monthly</option>
            </select>
          </div>

          <div className="mb-5">
            <label className="block mb-2 font-medium text-[#8597A8] text-sm">
              Timezone:
            </label>
            <input
              type="text"
              value={timezone}
              onChange={(e) => setTimezone(e.target.value)}
              placeholder="Enter timezone"
              className="w-full p-3 border border-[#E5E7EB] rounded-md text-sm text-[#32383F] bg-white focus:outline-none focus:border-[#3B82F6] focus:ring-2 focus:ring-[#3B82F6]/10"
            />
          </div>

          <button
            onClick={handleGetData}
            className="w-full p-3 bg-[#FFB800] text-[#32383F] border-none rounded-md text-sm font-medium cursor-pointer transition-all duration-200 mb-6 hover:bg-[#E5A600]"
          >
            Get Data
          </button>

          <div className="w-full min-h-[200px] max-h-[400px] overflow-y-auto bg-[#F9FAFB] border border-[#E5E7EB] rounded-md">
            <pre className="m-0 p-4 font-mono whitespace-pre-wrap break-words text-sm text-[#32383F]">
              {response}
            </pre>
          </div>
        </div>

        {showRawData && (
          <div className="flex-1 bg-white rounded-lg shadow-sm p-6 min-w-0">
            <h1 className="text-2xl font-semibold mb-6 text-[#32383F]">
              Raw Data
            </h1>

            <div className="flex gap-4 mb-6">
              <button
                className={`flex-1 p-3 border border-[#E5E7EB] rounded-md text-sm font-medium cursor-pointer transition-all duration-200 ${
                  !isDelete
                    ? "bg-[#FFB800] text-[#32383F] border-[#FFB800]"
                    : "bg-white text-[#8597A8] hover:border-[#FFB800] hover:text-[#FFB800]"
                }`}
                onClick={() => setIsDelete(false)}
              >
                Post
              </button>
              <button
                className={`flex-1 p-3 border border-[#E5E7EB] rounded-md text-sm font-medium cursor-pointer transition-all duration-200 ${
                  isDelete
                    ? "bg-[#FFB800] text-[#32383F] border-[#FFB800]"
                    : "bg-white text-[#8597A8] hover:border-[#FFB800] hover:text-[#FFB800]"
                }`}
                onClick={() => setIsDelete(true)}
              >
                Delete
              </button>
            </div>

            {isDelete ? (
              <>
                <div className="mb-5">
                  <label className="block mb-2 font-medium text-[#8597A8] text-sm">
                    Device IDs (one per line):
                  </label>
                  <textarea
                    value={rawDeviceIds}
                    onChange={(e) => setRawDeviceIds(e.target.value)}
                    placeholder="Enter device IDs, one per line"
                    className="w-full min-h-[100px] p-3 border border-[#E5E7EB] rounded-md font-mono resize-y text-sm text-[#32383F] bg-white focus:outline-none focus:border-[#3B82F6] focus:ring-2 focus:ring-[#3B82F6]/10"
                  />
                </div>

                <div className="mb-5">
                  <label className="block mb-2 font-medium text-[#8597A8] text-sm">
                    From:
                  </label>
                  <input
                    type="datetime-local"
                    value={rawFromDate}
                    onChange={(e) => setRawFromDate(e.target.value)}
                    className="w-full p-3 border border-[#E5E7EB] rounded-md text-sm text-[#32383F] bg-white focus:outline-none focus:border-[#3B82F6] focus:ring-2 focus:ring-[#3B82F6]/10"
                  />
                </div>

                <div className="mb-5">
                  <label className="block mb-2 font-medium text-[#8597A8] text-sm">
                    To:
                  </label>
                  <input
                    type="datetime-local"
                    value={rawToDate}
                    onChange={(e) => setRawToDate(e.target.value)}
                    className="w-full p-3 border border-[#E5E7EB] rounded-md text-sm text-[#32383F] bg-white focus:outline-none focus:border-[#3B82F6] focus:ring-2 focus:ring-[#3B82F6]/10"
                  />
                </div>
              </>
            ) : (
              <div className="mb-5">
                <label className="block mb-2 font-medium text-[#8597A8] text-sm">
                  Data to Post:
                </label>
                <textarea
                  value={rawDataInput}
                  onChange={(e) => setRawDataInput(e.target.value)}
                  placeholder='[
  {
    "device_id": "device123",
    "start_sess": "2024-03-27T10:00:00",
    "recorded_at": "2024-03-27T11:00:00",
    "tot_relay": 3600
  }
]'
                  className="w-full min-h-[200px] p-3 border border-[#E5E7EB] rounded-md font-mono resize-y text-sm text-[#32383F] bg-white focus:outline-none focus:border-[#3B82F6] focus:ring-2 focus:ring-[#3B82F6]/10"
                />
              </div>
            )}

            <button
              onClick={handleRawData}
              className="w-full p-3 bg-[#FFB800] text-[#32383F] border-none rounded-md text-sm font-medium cursor-pointer transition-all duration-200 mb-6 hover:bg-[#E5A600]"
            >
              {isDelete ? "Delete Data" : "Post Data"}
            </button>

            <div className="w-full min-h-[100px] max-h-[200px] overflow-y-auto bg-[#F9FAFB] border border-[#E5E7EB] rounded-md">
              <pre className="m-0 p-4 font-mono whitespace-pre-wrap break-words text-sm text-[#32383F]">
                {rawDataOutput}
              </pre>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Usage = () => {
  const [user] = useUserStore((state) => [state.user]);
  const location = useLocation();

  // Check authentication
  if (!isAuthenticated()) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // Check if user is superadmin of org 1
  if (!user || user.role !== "superadmin" || user.organization_id !== 1) {
    return <Navigate to="/dashboard/organizations" replace />;
  }

  return (
    <Layout user={user}>
      <UsageContent />
    </Layout>
  );
};

export default Usage;
