import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";

// Global Error Handler for ResizeObserver loop errors
const observerErrorHandler = (event) => {
  if (event.message.includes("ResizeObserver loop")) {
    event.stopPropagation();
    event.preventDefault();
  }
};

window.addEventListener("error", observerErrorHandler);

// Patch ResizeObserver to suppress error gracefully and avoid loop
const patchResizeObserver = () => {
  const OriginalResizeObserver = window.ResizeObserver;

  if (OriginalResizeObserver) {
    window.ResizeObserver = class extends OriginalResizeObserver {
      constructor(callback) {
        super((entries, observer) => {
          requestAnimationFrame(() => {
            try {
              callback(entries, observer);
            } catch (error) {
              if (error.message.includes("ResizeObserver loop")) {
                console.warn("ResizeObserver loop error suppressed.");
              } else {
                throw error;
              }
            }
          });
        });
      }
    };
  }
};

patchResizeObserver();

const queryClient = new QueryClient();

// Render the App
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);
