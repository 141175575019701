import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

/**
 * Reusable Table component with enhanced mobile scrolling indicators
 */
const Table = ({
  columns,
  data,
  onRowClick,
  className = "",
  headerClassName = "",
  rowClassName = "",
  cellClassName = "",
  emptyMessage = "No data available",
  isLoading = false,
  minColumnWidth = "8rem", // Default min width (approximately 32 characters)
}) => {
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [tableWidth, setTableWidth] = useState(0);
  const tableContainerRef = useRef(null);

  // Calculate if scroll indicator should be shown
  useEffect(() => {
    // Calculate table width based on number of columns and min width
    const calculatedTableWidth = columns.reduce((total, column) => {
      const width = parseInt(column.minWidth || minColumnWidth);
      return total + width;
    }, 0);

    setTableWidth(calculatedTableWidth);

    // Check if table is wider than container
    const checkScrollable = () => {
      const container = tableContainerRef.current;
      if (container) {
        setContainerWidth(container.clientWidth);
        setShowScrollIndicator(container.scrollWidth > container.clientWidth);
      }
    };

    // Initial check
    setTimeout(checkScrollable, 100); // Slight delay to ensure rendering is complete

    // Add event listeners
    window.addEventListener("resize", checkScrollable);

    return () => {
      window.removeEventListener("resize", checkScrollable);
    };
  }, [columns, minColumnWidth, data]);

  // Handle scroll events
  const handleScroll = (e) => {
    setScrollPosition(e.target.scrollLeft);
  };

  if (isLoading) {
    return (
      <div className="w-full flex justify-center items-center py-8">
        <div className="animate-pulse text-gray-500">Loading...</div>
      </div>
    );
  }

  if (!data || data.length === 0) {
    return (
      <div className="w-full flex justify-center items-center py-8">
        <div className="text-gray-500">{emptyMessage}</div>
      </div>
    );
  }

  const isScrolledToEnd =
    containerWidth &&
    tableWidth &&
    scrollPosition >= tableWidth - containerWidth - 10;
  const scrollPercentage =
    containerWidth && tableWidth
      ? Math.min(
          100,
          Math.round((scrollPosition / (tableWidth - containerWidth)) * 100)
        )
      : 0;

  return (
    <div className={`relative ${className}`}>
      {/* Mobile scroll indicator - more prominent */}
      {showScrollIndicator && (
        <div className="md:hidden mb-3">
          <div className="flex items-center justify-between text-sm text-gray-600 mb-1 font-medium">
            <div className="flex items-center">
              <svg
                className="w-5 h-5 mr-1 text-[#E5A50E] animate-pulse"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <span>Swipe to see more columns</span>
            </div>
          </div>
        </div>
      )}

      {/* Table container with scroll event */}
      <div
        ref={tableContainerRef}
        className="table-scroll-container overflow-auto w-full relative"
        onScroll={handleScroll}
      >
        {/* Right fade effect when table is scrollable */}
        {showScrollIndicator && !isScrolledToEnd && (
          <div className="absolute right-0 top-0 bottom-0 w-16 bg-gradient-to-r from-transparent to-gray-100 pointer-events-none md:hidden z-10"></div>
        )}

        {/* Left fade effect when scrolled */}
        {showScrollIndicator && scrollPosition > 10 && (
          <div className="absolute left-0 top-0 bottom-0 w-16 bg-gradient-to-l from-transparent to-gray-100 pointer-events-none md:hidden z-10"></div>
        )}

        <table
          className="divide-y-8 divide-[#F4F4F4] w-full table-auto"
          style={{
            minWidth: tableWidth > containerWidth ? `${tableWidth}px` : "100%",
          }}
        >
          <colgroup>
            {columns.map((column, index) => (
              <col
                key={`col-${column.key}`}
                style={{
                  minWidth: column.minWidth || minColumnWidth,
                  width: column.width || "auto",
                }}
              />
            ))}
          </colgroup>
          <thead className="sticky top-0 z-10 bg-white">
            <tr>
              {columns.map((column) => (
                <th
                  key={column.key}
                  className={`px-2 py-2 text-left md:text-base text-sm font-normal text-[#B0CAD1] tracking-wider ${
                    column.headerClassName || headerClassName
                  } ${column.align === "center" ? "text-center" : ""}`}
                  style={{
                    minWidth: column.minWidth || minColumnWidth,
                    width: column.width || "auto",
                  }}
                >
                  <div className="flex items-center">
                    <span className="mr-2">{column.header}</span>
                    {column.sortable && column.sortComponent}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y-8 divide-[#F4F4F4]">
            {data.map((row, rowIndex) => (
              <tr
                key={row.id || rowIndex}
                className={`bg-white ${rowClassName} hover:bg-gray-50`}
                onClick={() => onRowClick && onRowClick(row)}
                style={{ cursor: onRowClick ? "pointer" : "default" }}
              >
                {columns.map((column, colIndex) => {
                  const cellContent = column.render
                    ? column.render(row)
                    : row[column.key];

                  // Apply rounded corners to first and last cells in the row
                  const isFirstCell = colIndex === 0;
                  const isLastCell = colIndex === columns.length - 1;
                  const roundedClass = isFirstCell
                    ? "rounded-l-full"
                    : isLastCell
                    ? "rounded-r-full"
                    : "";

                  return (
                    <td
                      key={`${row.id || rowIndex}-${column.key}`}
                      className={`px-2 py-3 ${roundedClass} ${
                        column.cellClassName || cellClassName
                      } ${column.align === "center" ? "text-center" : ""}`}
                      style={{
                        minWidth: column.minWidth || minColumnWidth,
                        width: column.width || "auto",
                        whiteSpace: column.wrap ? "normal" : "nowrap",
                      }}
                    >
                      <div className="text-base text-[#1E3A56] overflow-hidden text-ellipsis">
                        {cellContent}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Mobile scroll hint animation - appears briefly */}
      {showScrollIndicator && (
        <div className="md:hidden mt-2 text-xs text-gray-500 text-center">
          <div className="flex justify-center items-center">
            <svg
              className="w-4 h-4 mr-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 19l-7-7 7-7"
              ></path>
            </svg>
            <span>Swipe horizontally to view all columns</span>
            <svg
              className="w-4 h-4 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      render: PropTypes.func,
      sortable: PropTypes.bool,
      sortComponent: PropTypes.node,
      align: PropTypes.oneOf(["left", "center", "right"]),
      headerClassName: PropTypes.string,
      cellClassName: PropTypes.string,
      minWidth: PropTypes.string, // Minimum width for the column
      width: PropTypes.string, // Fixed width for the column
      wrap: PropTypes.bool, // Whether to wrap text in the column
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  cellClassName: PropTypes.string,
  emptyMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  minColumnWidth: PropTypes.string, // Default minimum width for all columns
};

export default Table;
