import React, { useState } from "react";
import PropTypes from "prop-types";

/**
 * Sort directions
 */
const SORT_DIRECTIONS = {
  ASC: "asc",
  DESC: "desc",
  NONE: "none",
};

/**
 * Reusable SortButton component
 */
const SortButton = ({
  text,
  sortBy,
  handlerToggler,
  initialDirection = SORT_DIRECTIONS.NONE,
  className = "",
}) => {
  const [direction, setDirection] = useState(initialDirection);

  const handleClick = () => {
    // Cycle through sort directions: none -> asc -> desc -> none
    let newDirection;
    if (direction === SORT_DIRECTIONS.NONE) {
      newDirection = SORT_DIRECTIONS.ASC;
    } else if (direction === SORT_DIRECTIONS.ASC) {
      newDirection = SORT_DIRECTIONS.DESC;
    } else {
      newDirection = SORT_DIRECTIONS.NONE;
    }

    setDirection(newDirection);
    handlerToggler(sortBy, newDirection);
  };

  return (
    <button
      onClick={handleClick}
      className={`inline-flex items-center text-xs font-medium ${className}`}
      data-testid={`sort-button-${sortBy}`}
    >
      <span className="mr-1">{text}</span>
      <span className="flex flex-col">
        <svg
          className={`w-2 h-2 ${
            direction === SORT_DIRECTIONS.ASC
              ? "text-blue-600"
              : "text-gray-400"
          }`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 5h8L5 1 1 5z"
          />
        </svg>
        <svg
          className={`w-2 h-2 ${
            direction === SORT_DIRECTIONS.DESC
              ? "text-blue-600"
              : "text-gray-400"
          }`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 1 5 5 1 1"
          />
        </svg>
      </span>
    </button>
  );
};

SortButton.propTypes = {
  text: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  handlerToggler: PropTypes.func.isRequired,
  initialDirection: PropTypes.oneOf(Object.values(SORT_DIRECTIONS)),
  className: PropTypes.string,
};

export { SortButton, SORT_DIRECTIONS };
export default SortButton;
