import React from "react";
import PropTypes from "prop-types";

/**
 * Button variants
 */
const VARIANTS = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  DANGER: "danger",
  SUCCESS: "success",
  OUTLINE: "outline",
};

/**
 * Button sizes
 */
const SIZES = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
};

/**
 * Reusable Button component
 */
const Button = ({
  children,
  onClick,
  type = "button",
  disabled = false,
  variant = VARIANTS.PRIMARY,
  size = SIZES.MEDIUM,
  className = "",
  fullWidth = false,
  ...props
}) => {
  // Base classes
  const baseClasses =
    "font-medium rounded-lg focus:outline-none transition-colors";

  // Size classes
  const sizeClasses = {
    [SIZES.SMALL]: "py-1 px-3 text-xs",
    [SIZES.MEDIUM]: "py-2 px-4 text-sm",
    [SIZES.LARGE]: "py-3 px-6 text-base",
  };

  // Variant classes
  const variantClasses = {
    [VARIANTS.PRIMARY]:
      "bg-blue-600 text-white hover:bg-blue-700 focus:ring-2 focus:ring-blue-300",
    [VARIANTS.SECONDARY]:
      "bg-gray-200 text-gray-800 hover:bg-gray-300 focus:ring-2 focus:ring-gray-300",
    [VARIANTS.DANGER]:
      "bg-red-600 text-white hover:bg-red-700 focus:ring-2 focus:ring-red-300",
    [VARIANTS.SUCCESS]:
      "bg-green-600 text-white hover:bg-green-700 focus:ring-2 focus:ring-green-300",
    [VARIANTS.OUTLINE]:
      "bg-transparent border border-[#8597A8] text-[#8597A8] hover:bg-gray-100",
  };

  // Disabled classes
  const disabledClasses = disabled
    ? "opacity-50 cursor-not-allowed"
    : "cursor-pointer";

  // Width classes
  const widthClasses = fullWidth ? "w-full" : "";

  // Combine all classes
  const buttonClasses = `
    ${baseClasses}
    ${sizeClasses[size]}
    ${variantClasses[variant]}
    ${disabledClasses}
    ${widthClasses}
    ${className}
  `;

  return (
    <button
      type={type}
      className={buttonClasses}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  size: PropTypes.oneOf(Object.values(SIZES)),
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
};

// Export component and constants
export { Button, VARIANTS, SIZES };
export default Button;
