import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api, { baseURL } from "../../utils/api";
import axios from "axios";
import { useUserStore } from "../../storesZustand/useUserStore";
import Spinner from "../../components/UI/Spinner";

const LoginAuthentication = () => {
  const [setUsersDataAfterLogin] = useUserStore((state) => [
    state.setUsersDataAfterLogin,
  ]);

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [user] = useUserStore((state) => [state.user]);
  const role = user?.role;
  const token = localStorage.getItem("token");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSignIn = (event) => {
    event.preventDefault();
    setIsLoading(true);
    api
      .post(
        "/user/login",
        {
          email: email.toLowerCase(),
          password,
        },
        {
          headers: {
            "x-auth-token": token,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          toast.success(res?.data?.message);
          //Zustand store
          setUsersDataAfterLogin(res?.data?.data);
          localStorage.setItem("token", res?.data?.tokens?.idToken);
          localStorage.setItem("refreshToken", res?.data?.tokens?.refreshToken);
          if (res?.data?.data?.role === "employee") {
            navigate("/training-portal");
            return;
          }
          navigate("/");
        }
      })
      .catch(({ response }) => {
        setIsLoading(false);
        if (response.status === 401) {
          axios.post(`${baseURL}/user/resendCode`, {
            email,
          });
          navigate("/verifyactivestatuscode", {
            state: {
              email: email,
            },
          });
        }
        // console.log("response", response);
        if (response.status !== 401) toast.error(response?.data.message);
        console.log(response?.data.message);
      });
  };

  const [show, setShow] = useState(false);

  const handleToggle = () => {
    setShow(!show);
  };

  if (token !== null) {
    if (role === "employee") return <Navigate to="/training-portal" replace />;

    if (role === "admin") {
      return <Navigate to="/dashboard/locations" />;
    }
    if (role === "superadmin" && user.organization_id !== 1) {
      return <Navigate to="/dashboard/locations" />;
    }
    if (role === "superadmin" && user.organization_id === 1) {
      return <Navigate to="/dashboard/organizations" />;
    }
  }

  return (
    <React.Fragment>
      <div
        id="login_right_column"
        className="bg-[#040824] w-full h-screen overflow-scroll my-scroller text-white"
      >
        <div className="max-w-[476px] w-[100%] m-auto lg:pt-[130px] px-[10px]">
          <img
            src="/images/halcyon-logo-glow.svg"
            alt="Halcyon Logo"
            width="170" // Set the width of the logo
            height="50" // Set the height of the logo
            className="mx-auto lg:hidden block"
          />
          <img src="/images/Rectangle.png" alt="" />
          <p className="text-[45px] font-bold mt-3">Halcyon Eco Wiz </p>
          <p className="text-[20px] font-normal mt-[5px] tracking-[0.094px]">
            With Halcyon Eco Wiz you can complete your mandatory training and
            business owners/manager can monitor savings.
          </p>
          <form onSubmit={handleSignIn}>
            <div className="form mt-5 ">
              <div className="relative">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-7 h-7 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                    />
                  </svg>
                </span>
                <input
                  className="pl-12 w-full pr-3 py-2 bg-[#2A2E45] h-[56px] rounded border border-gray-300  focus:outline-none"
                  type="email"
                  required
                  placeholder="john@gmail.com"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              <div className="relative mt-5">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-7 h-7 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                    />
                  </svg>
                </span>
                <input
                  className="pl-12 w-full pr-3 py-2 bg-[#2A2E45] h-[56px] rounded border border-gray-300  focus:outline-none"
                  type={show ? "text" : "password"}
                  placeholder="***********"
                  value={password}
                  required
                  onChange={handlePasswordChange}
                />
                {show ? (
                  <svg
                    onClick={handleToggle}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-4 h-4 absolute right-3 top-5 cursor-pointer"
                  >
                    <path
                      strokeLinecap="round"
                      stroke-linejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                ) : (
                  <svg
                    onClick={handleToggle}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-4 h-4 absolute right-3 top-5 cursor-pointer"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                    />
                  </svg>
                )}
              </div>
            </div>
            <div>
              <button type="submit" className="sign-in-button">
                {isLoading ? (
                  <Spinner
                    size={"w-8 h-8"}
                    color={"text-gray-200 dark:text-gray-600"}
                  />
                ) : (
                  <span>SIGN IN</span>
                )}
              </button>
            </div>
          </form>
          <p className="text-center text-[20px]  mt-3">Forgot your password?</p>
          <p
            onClick={() => navigate("/reset-password")}
            className="text-[#E4A61A] cursor-pointer italic text-[20px] font-[100] text-center mt-[15px]"
          >
            Reset Password
          </p>
          <p className="text-center text-[20px] mt-3 ">
            Don't yet have an account?
          </p>
          <p
            onClick={() => navigate("/register")}
            className="text-[#E4A61A] cursor-pointer text-[20px] italic font-[100] text-center mt-[15px]"
          >
            Register New Account
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginAuthentication;
