import { useState, useEffect } from "react";

const useSortData = (initialData) => {
  const [sortedData, setSortedData] = useState(initialData);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortParam, setSortParam] = useState(null);

  useEffect(() => {
    setSortedData(initialData);
  }, [initialData]);

  const sortData = (param) => {
    const sorted = [...sortedData].sort((a, b) => {
      const valueA =
        typeof a[param] === "string" ? a[param].toUpperCase() : a[param];
      const valueB =
        typeof b[param] === "string" ? b[param].toUpperCase() : b[param];

      if (sortOrder === "asc") {
        return valueA > valueB ? 1 : -1;
      } else {
        return valueB > valueA ? 1 : -1;
      }
    });

    setSortedData(sorted);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortParam(param);
  };

  const sortDataNum = (param) => {
    const sorted = [...initialData].sort((a, b) => {
      // Convert string to number if the parameter is a number stored as string
      const valueA = isNaN(Number(a[param])) ? a[param] : Number(a[param]);
      const valueB = isNaN(Number(b[param])) ? b[param] : Number(b[param]);

      if (sortOrder === "asc") {
        return valueA > valueB ? 1 : -1;
      } else {
        return valueB > valueA ? 1 : -1;
      }
    });

    setSortedData(sorted);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortParam(param);
  };

  return { sortedData, sortData, sortDataNum, sortParam };
};

export default useSortData;
