import React from "react";
import PropTypes from "prop-types";

/**
 * Skeleton component for loading animations
 *
 * This component displays a pulsing animation for content that is loading.
 * It can be customized for different shapes and sizes.
 */
const Skeleton = ({ className, height, width, rounded = "rounded-md" }) => {
  return (
    <div
      className={`animate-pulse bg-gray-200 ${rounded} ${className}`}
      style={{
        height: height || "100%",
        width: width || "100%",
      }}
    />
  );
};

/**
 * StatBlockSkeleton component for loading StatBlock animations
 */
export const StatBlockSkeleton = ({ variant = "light" }) => {
  const variantClass = variant === "dark" ? "bg-gray-700" : "bg-white";
  const textColor = variant === "dark" ? "text-white" : "text-[#32383F]";

  return (
    <div className={`${variantClass} relative w-full h-full p-5 rounded-xl`}>
      <div className="flex flex-col h-full">
        {/* Icon placeholder */}
        <Skeleton className="rounded-full" height="44px" width="44px" />

        {/* Description - actual text, not a skeleton */}
        <p
          className={`text-${
            variant === "dark" ? "#C6C6C6" : "#787878"
          } mt-2 text-sm`}
        >
          {variant === "dark"
            ? "Total Savings"
            : variant === "carbon"
            ? "Carbon Saved"
            : variant === "litres"
            ? "Litres of oil saved"
            : "Savings last 30 Days"}
        </p>

        {/* Amount placeholder - at the bottom */}
        <div className="mt-auto">
          <Skeleton height="28px" width="60%" />
        </div>
      </div>
    </div>
  );
};

/**
 * ChartSkeleton component for loading Chart animations
 */
export const ChartSkeleton = () => {
  return (
    <div className="bg-white w-full h-[300px] p-5 rounded-xl">
      {/* Title - actual text, not a skeleton */}
      <p className="font-manrope pb-2 text-[18px]">Savings Overview</p>

      {/* Chart area placeholder */}
      <div className="h-[calc(100%-30px)] w-full">
        <Skeleton height="100%" width="100%" />
      </div>
    </div>
  );
};

/**
 * TableRowSkeleton component for loading Table row animations
 */
export const TableRowSkeleton = ({ columns = 4 }) => {
  return (
    <tr className="bg-white rounded-lg">
      {[...Array(columns)].map((_, i) => (
        <td
          key={i}
          className={`px-6 py-3 ${i === 0 ? "rounded-l-full" : ""} ${
            i === columns - 1 ? "rounded-r-full" : ""
          }`}
        >
          <Skeleton height="24px" width={i === columns - 1 ? "160px" : "80%"} />
        </td>
      ))}
    </tr>
  );
};

/**
 * TableSkeleton component for loading Table animations
 */
export const TableSkeleton = ({
  rows = 5,
  columns = 4,
  headers = ["Location Name", "Address", "Contact Name", "Reports"],
}) => {
  return (
    <div className="w-full">
      <table className="divide-y-8 divide-[#F4F4F4] w-full">
        <thead>
          <tr>
            {headers.map((header, i) => (
              <th
                key={i}
                className="px-6 py-2 text-left md:text-base text-sm font-normal text-[#B0CAD1] tracking-wider"
              >
                {i === 0 ? (
                  <div className="flex items-center gap-2">
                    <p>{header}</p>
                    <div className="bg-[#8597A8] px-3 py-1 rounded-lg">
                      <div className="flex items-center justify-center gap-2.5">
                        <span className="text-white text-[11px] leading-6 whitespace-nowrap">
                          A - Z
                        </span>
                        <div className="h-[15px] w-[8px]">
                          <img src="/images/up-down-arrow.svg" alt="arrows" />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  header
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y-8 divide-[#F4F4F4]">
          {[...Array(rows)].map((_, i) => (
            <TableRowSkeleton key={i} columns={columns} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

/**
 * EmployeeListSkeleton component for loading Training Employee List animations
 *
 * This component creates skeleton placeholders for the employee training list
 * while preserving the header title.
 */
export const EmployeeListSkeleton = ({ rows = 6 }) => {
  return (
    <div>
      <div
        className="py-[26px] rounded-xl text-[#32383F] font-bold text-[20px] bg-white flex justify-center"
        style={{
          boxShadow: "0px 20px 100px 0px rgba(85, 85, 85, 0.05)",
        }}
      >
        Training Status of Employees
      </div>

      {[...Array(rows)].map((_, index) => (
        <div
          key={index}
          className="mt-[19px] bg-white px-[14px] pt-[14px] pb-[20px] rounded-xl"
        >
          <div className="flex justify-between">
            <div className="flex items-center">
              {/* Profile picture placeholder */}
              <Skeleton className="rounded-full" height="38px" width="38px" />

              {/* Name placeholder */}
              <div className="ml-[14px]">
                <Skeleton height="20px" width="120px" />
              </div>
            </div>

            {/* Remove user text - not showing in skeleton */}
            <div className="invisible">
              <p className="text-[#8597A8] text-[10px] font-medium">
                Remove User
              </p>
            </div>
          </div>

          <div className="grid grid-cols-2 my-[5px]">
            {/* Quiz status placeholder */}
            <Skeleton height="20px" width="100px" />

            {/* Date placeholder */}
            <Skeleton height="20px" width="120px" />
          </div>

          <div className="flex items-center">
            {/* Progress bar placeholder */}
            <div className="flex-grow mr-4">
              <Skeleton height="8px" width="100%" rounded="rounded-full" />
            </div>

            {/* Percentage placeholder */}
            <Skeleton height="20px" width="40px" />
          </div>

          {/* Certificate download link placeholder - only showing in some rows */}
          {index % 2 === 0 && (
            <div className="mt-2">
              <Skeleton height="16px" width="120px" />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

Skeleton.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  rounded: PropTypes.string,
};

StatBlockSkeleton.propTypes = {
  variant: PropTypes.oneOf(["dark", "light", "carbon", "litres"]),
};

TableSkeleton.propTypes = {
  rows: PropTypes.number,
  columns: PropTypes.number,
  headers: PropTypes.arrayOf(PropTypes.string),
};

TableRowSkeleton.propTypes = {
  columns: PropTypes.number,
};

EmployeeListSkeleton.propTypes = {
  rows: PropTypes.number,
};

export default Skeleton;
