export const menuItemsArray = [
  {
    id: 0,
    icon: "/images/addOrgIcon.svg",
    title: "Add Organization",
    availableForRoles: ["superadmin"],
    availableForCampaign: "Halcyon Eco",
  },
  {
    id: 1,
    icon: "/images/addLocIcon.svg",
    title: "Add Location",
    availableForRoles: ["superadmin"],
  },
  {
    id: 2,
    icon: "/images/addDeviceIcon.svg",
    title: "Add Device",
    availableForRoles: ["superadmin", "admin"],
  },
  {
    id: 3,
    icon: "/images/addUserIcon.svg",
    title: "Invite User",
    availableForRoles: ["superadmin", "admin"],
  },
];
