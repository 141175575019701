import React, { useEffect, useMemo, useState } from "react";
import SortButton from "../sort-button";
import useSortData from "../../hooks/useSortData";
import { useOrganizationStore } from "../../storesZustand/useOrganizationStore";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import DropdownDays from "../dropdown-days";
import SearchBar from "../search-bar";
import StatBlock from "../stat-block/StatBlock";
import { formattedCurrency } from "../../helpers/helpers";
import { useDashboardDataStore } from "../../storesZustand/useDashboardDataStore";
import { listOfDates } from "../../utils/constans/listOfDates";
import { useUserStore } from "../../storesZustand/useUserStore";
import { locale } from "../../utils/constans/locaLanguage";
import { useViewDasboardStore } from "../../storesZustand/useViewDasboardStore";
import { useDashboardData } from "../../services/dashboard/queries";
import BreadcrumbsComponent from "../UI/breadcrumbs";
import Chart from "../UI/chart";
import Map from "../map";
import { ModalTypes } from "../all-modal-window/modal-window";
import LoadingSpinner from "../LoadingSpinner";
import country from "country-list-js";
import ModalSelectCountries from "../UI/modal-select-countries";

const TableOrganizationSuperAdmin = () => {
  const [user] = useUserStore((state) => [state.user]);
  const location = useLocation();
  const { organizationId } = useParams();

  const { isLoading, data: queryData } = useDashboardData(
    user?.role_id,
    user?.organization_id,
    organizationId
  );

  const [dashboardData] = useDashboardDataStore((state) => [
    state.devicesWithLocationWithAnalytics,
  ]);

  const [organizations] = useOrganizationStore((state) => [
    state.organizations,
  ]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedOption, setSelectedOption] = useState(listOfDates[0].name);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(() => {
    // Try to get the cached country from localStorage
    const cachedCountry = localStorage.getItem("selectedCountry");
    return cachedCountry ? JSON.parse(cachedCountry) : null;
  });

  const [isOpen, isxOpen1, setIsOpen, setIsOpen1] = useViewDasboardStore(
    (store) => [store.open1, store.open2, store.setIsOpen, store.setIsOpen1]
  );
  const [setDisplayModalWindowType, setCurrentOpenIdForEditModalWindow] =
    useViewDasboardStore((state) => [
      state.setDisplayModalWindowType,
      state.setCurrentOpenIdForEditModalWindow,
    ]);

  const navigate = useNavigate();

  const { sortedData, sortData } = useSortData(organizations);

  // Get list of countries
  const countryNames = Object.values(country.all).map((code) => ({
    name: code.name,
    iso2: code.iso2,
  }));

  // Get unique countries from organizations
  const availableCountries = useMemo(() => {
    if (!organizations) return [];
    const uniqueCountries = new Set();
    const countryMap = {};

    // Add "All" option first
    countryMap["all"] = {
      name: "All",
      iso2: "ALL",
    };

    organizations.forEach((org) => {
      if (org.billing_country) {
        uniqueCountries.add(org.billing_country);
        // Store the first occurrence of each country
        if (!countryMap[org.billing_country]) {
          countryMap[org.billing_country] = {
            name: org.billing_country,
            iso2: country.all[org.billing_country]?.iso2 || org.billing_country,
          };
        }
      }
    });

    return Object.values(countryMap);
  }, [organizations]);

  // Only show dropdown if there's more than one country
  const showCountryDropdown = availableCountries.length > 1;

  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isOpen]);

  const handlerClickButton = (item) => {
    const organizationId = item.id;
    navigate(`/dashboard/organizations/${organizationId}`);
  };

  const handlerCickButtonForOpenEditModal = (id, typeModalWindow) => {
    setDisplayModalWindowType(typeModalWindow);
    setCurrentOpenIdForEditModalWindow(id);
  };

  const handlerCickButtonDawnloadData = (id, typeModalWindow) => {
    setDisplayModalWindowType(typeModalWindow);
    setCurrentOpenIdForEditModalWindow(id);
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    setIsOpen1(false);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    // Save to localStorage
    localStorage.setItem("selectedCountry", JSON.stringify(country));
  };

  // Clear cache on logout
  useEffect(() => {
    const handleLogout = () => {
      localStorage.removeItem("selectedCountry");
    };

    window.addEventListener("logout", handleLogout);
    return () => {
      window.removeEventListener("logout", handleLogout);
    };
  }, []);

  // Filter the sorted data based on the search query and selected country
  const filteredData = sortedData?.filter((item) => {
    const matchesSearch = Object.values(item).some((value) =>
      value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Don't filter by country if "All" is selected
    const matchesCountry =
      !selectedCountry ||
      selectedCountry.name === "All" ||
      item.billing_country?.toLowerCase() ===
        selectedCountry.name.toLowerCase();

    return matchesSearch && matchesCountry;
  });

  // Determine if data is ready to display
  const isDataReady = !isLoading && dashboardData && sortedData;

  return (
    <div className="w-[100%] h-full sm:p-10 p-4 pt-[100px] sm:pr-[120px] sm:pt-[25px]">
      <div
        className={`w-full h-[40px] flex flex-row justify-between items-center py-2 px-2 ${
          screenWidth <= 640 || screenWidth >= 1025 ? "hidden" : "block"
        }`}
      >
        <button onClick={() => toggleDrawer()}>
          <img src="/images/burger-open.svg" alt="burger" />
        </button>
      </div>
      {screenWidth < 640 && (
        <div className="fixed z-30 top-0 left-0 w-full h-[88px] bg-white flex flex-row justify-between items-center py-7 px-5">
          <button onClick={() => toggleDrawer()}>
            <img src="/images/burger-open.svg" alt="burger" />
          </button>
        </div>
      )}
      <div className="flex justify-between mb-4 items-center">
        <div className="flex items-start gap-2">
          {user && (
            <BreadcrumbsComponent
              breadCrumbsArr={[
                { name: "Overview", url: "" },
                { name: user.organization_name, url: location.pathname },
              ]}
            />
          )}
        </div>
        <div className="flex items-start gap-4">
          {screenWidth >= 640 && (
            <>
              {showCountryDropdown && (
                <div className="w-[250px]">
                  <ModalSelectCountries
                    data={availableCountries}
                    optionDefault="All"
                    defaultOption={
                      selectedCountry || { name: "All", iso2: "ALL" }
                    }
                    onSelect={handleCountrySelect}
                  />
                </div>
              )}
              <SearchBar
                placeholder="Type to search..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </>
          )}
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center w-full h-[200px]">
          <LoadingSpinner isFullPage={true} />
        </div>
      ) : (
        <>
          <div className="w-full h-auto md:h-[320px] flex flex-col md:flex-row overflow-hidden">
            <div className="w-full md:flex-[2] h-full">
              <div className="flex flex-col w-full h-full">
                <div className="flex flex-col md:flex-row w-full h-1/2">
                  <div className="w-full md:flex-1 p-1">
                    <StatBlock
                      variant="dark"
                      icon="/images/Frame (10).svg"
                      description="Total Savings"
                      amount={
                        dashboardData?.totalLitres
                          ? formattedCurrency(
                              dashboardData?.totalSavings,
                              dashboardData?.currency
                            )
                          : "0"
                      }
                      unit="€"
                    />
                  </div>
                  <div className="w-full md:flex-1 p-1">
                    <StatBlock
                      variant="light"
                      icon="/images/carbon-footprint.svg"
                      description="Carbon Saved"
                      amount={
                        dashboardData?.totalLitres
                          ? parseInt(
                              dashboardData.totalLitres * 3.81
                            ).toLocaleString(locale)
                          : "0"
                      }
                      unit="kg CO2e"
                    />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row w-full h-1/2">
                  <div className="w-full md:flex-1 p-1">
                    <StatBlock
                      variant="light"
                      icon="/images/Frame (12).svg"
                      description="Savings last 30 Days"
                      amount={
                        dashboardData?.totalLitresLast30Days
                          ? formattedCurrency(
                              dashboardData?.savingsLast30Days,
                              dashboardData?.currency
                            )
                          : "0"
                      }
                      unit="€"
                    />
                  </div>
                  <div className="w-full md:flex-1 p-1">
                    <StatBlock
                      variant="light"
                      icon="/images/Frame (12).svg"
                      description="Litres of oil saved"
                      amount={
                        dashboardData?.totalLitres
                          ? parseInt(dashboardData?.totalLitres).toLocaleString(
                              locale
                            ) + " L"
                          : "0"
                      }
                      unit="L"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:flex-[4] h-[300px] md:h-full p-1 min-w-0">
              <Chart
                variant="light"
                data={dashboardData?.last7daysData || {}}
                screenWidth={screenWidth}
              />
            </div>
          </div>
          <div className="flex gap-8 flex-col mt-8 sm:flex-row">
            {screenWidth < 640 && (
              <>
                {showCountryDropdown && (
                  <div className="w-full">
                    <ModalSelectCountries
                      data={availableCountries}
                      optionDefault="All"
                      defaultOption={
                        selectedCountry || { name: "All", iso2: "ALL" }
                      }
                      onSelect={handleCountrySelect}
                    />
                  </div>
                )}
                <SearchBar
                  placeholder="Type to search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </>
            )}
          </div>
          <div className="mt-1 w-100">
            <div className="table-container overflow-auto w-full">
              <table className="divide-y-8 divide-[#F4F4F4] w-full">
                <thead>
                  <tr>
                    <th className="whitespace-nowrap flex items-center gap-2 px-6 py-2 text-left md:text-base text-sm font-normal text-[#B0CAD1] tracking-wider">
                      <p>Location Name</p>
                      <SortButton
                        handlerToggler={sortData}
                        text="A - Z"
                        sortBy="name"
                      />
                    </th>
                    <th className="px-6 py-2 text-start md:text-base text-sm font-normal text-[#B0CAD1] tracking-wider">
                      Address
                    </th>
                    <th className="px-6 py-2 text-start md:text-base text-sm font-normal text-[#B0CAD1] tracking-wider">
                      Contact Name
                    </th>
                    <th className="px-6 py-2 text-center md:text-base text-sm font-normal text-[#B0CAD1] tracking-wider">
                      Reports
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y-8 divide-[#F4F4F4]">
                  {filteredData?.map((item, index) => (
                    <tr
                      className="bg-white rounded-lg"
                      key={item?.id}
                      onClick={() => handlerClickButton(item)}
                    >
                      <td className="px-6 py-3 whitespace-nowrap rounded-l-full">
                        <div className="flex gap-3">
                          <img
                            className="cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              handlerCickButtonForOpenEditModal(
                                item.id,
                                ModalTypes.EDIT_ORGANIZATION
                              );
                            }}
                            src="/images/pencil.svg"
                            alt=""
                          />
                          <div className="text-base font-medium text-[#1E3A56]">
                            {item?.name ? item?.name : ""}
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="flex gap-3">
                          <div className="text-base text-[#1E3A56]">
                            {item?.address1 ? item.address1 : ""}
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="flex gap-3">
                          <div className="text-base text-[#1E3A56]">
                            {item?.billing_contact_name
                              ? item?.billing_contact_name
                              : ""}
                          </div>
                        </div>
                      </td>
                      <td className="lg:px-6 px-20 py-3 whitespace-nowrap rounded-r-full">
                        <div className="text-base text-[#1E3A56] text-center flex gap-2 float-right">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handlerClickButton(item);
                            }}
                            className="w-[80px] h-[30px] flex justify-center items-center font-ABeeZee text-xs font-normal text-[#8597A8] border border-[#8597A8] rounded-[7px] py-3 px-6"
                          >
                            View
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handlerCickButtonDawnloadData(
                                item.id,
                                ModalTypes.DOWNLOAD_ORG_DATA
                              );
                            }}
                            className="w-[80px] h-[30px] flex justify-center items-center font-ABeeZee text-xs font-normal text-white bg-[#8597A8] border border-[#8597A8] rounded-[7px] py-3 px-6"
                          >
                            Download
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div>
              {dashboardData?.locationsWithAnalyticsByDays &&
              dashboardData?.locationsWithAnalyticsByDays.length > 0 ? (
                <Map devices={dashboardData.locationsWithAnalyticsByDays} />
              ) : null}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TableOrganizationSuperAdmin;
