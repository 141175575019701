import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useOrganizationData } from "../../services/organization/queries";
import Table from "./Table";
import { SortButton, SORT_DIRECTIONS } from "../UI";
import { Button } from "../UI";
import useSortData from "../../hooks/useSortData";

/**
 * TableEmployeesSummary component for displaying organization employee data
 */
const TableEmployeesSummary = () => {
  const {
    data: organizationData,
    error: errorOrganization,
    isLoading,
  } = useOrganizationData();
  const { sortedData, sortData, sortDataNum, sortParam, sortDirection } =
    useSortData(organizationData);

  // Define columns for the table
  const columns = useMemo(
    () => [
      {
        key: "name",
        header: "Name",
        sortable: true,
        sortComponent: (
          <SortButton
            handlerToggler={(param) => sortData(param)}
            text="A - Z"
            sortBy="name"
            initialDirection={
              sortParam === "name" ? sortDirection : SORT_DIRECTIONS.NONE
            }
          />
        ),
        headerClassName: "pl-4",
        cellClassName: "pl-4",
        minWidth: "12rem", // Minimum width for Name column
      },
      {
        key: "address",
        header: "Address",
        sortable: true,
        sortComponent: (
          <SortButton
            handlerToggler={(param) => sortData(param)}
            text="A - Z"
            sortBy="address1"
            initialDirection={
              sortParam === "address1" ? sortDirection : SORT_DIRECTIONS.NONE
            }
          />
        ),
        render: (row) => {
          return (
            (row?.address1 || "") +
            (row?.address1 && row?.billing_city ? ", " : "") +
            (row?.billing_city || "")
          );
        },
        minWidth: "16rem", // Minimum width for Address column
        wrap: true, // Allow text wrapping for addresses
      },
      {
        key: "courses_complete",
        header: "Courses Complete",
        align: "center",
        sortable: true,
        sortComponent: (
          <SortButton
            handlerToggler={(param) => sortDataNum(param)}
            text=""
            sortBy="courses_complete"
            initialDirection={
              sortParam === "courses_complete"
                ? sortDirection
                : SORT_DIRECTIONS.NONE
            }
          />
        ),
        minWidth: "10rem", // Minimum width for Courses Complete column
      },
      {
        key: "num_employees",
        header: "Total No. of Employees",
        align: "center",
        sortable: true,
        sortComponent: (
          <SortButton
            handlerToggler={(param) => sortDataNum(param)}
            text=""
            sortBy="num_employees"
            initialDirection={
              sortParam === "num_employees"
                ? sortDirection
                : SORT_DIRECTIONS.NONE
            }
          />
        ),
        minWidth: "12rem", // Minimum width for Total No. of Employees column
      },
      {
        key: "num_locations",
        header: "Locations",
        align: "center",
        sortable: true,
        sortComponent: (
          <SortButton
            handlerToggler={(param) => sortDataNum(param)}
            text=""
            sortBy="num_locations"
            initialDirection={
              sortParam === "num_locations"
                ? sortDirection
                : SORT_DIRECTIONS.NONE
            }
          />
        ),
        minWidth: "8rem", // Minimum width for Locations column
      },
      {
        key: "actions",
        header: "",
        render: (row) => {
          return row?.num_locations > 0 ? (
            <div className="text-center">
              <Link to={`/training/org/${row?.id}`}>
                <Button variant="outline" size="small">
                  View
                </Button>
              </Link>
            </div>
          ) : null;
        },
        minWidth: "6rem", // Minimum width for Actions column
      },
    ],
    [sortData, sortDataNum, sortParam, sortDirection]
  );

  // Filter data based on selected option (hardcoded to "All Employees" for now)
  const filteredData = useMemo(() => {
    if (!sortedData) return [];

    return sortedData.filter((person) => {
      return true; // "All Employees" is the default filter
    });
  }, [sortedData]);

  if (errorOrganization) {
    return (
      <div className="text-red-500 p-4">Error loading organization data</div>
    );
  }

  return (
    <div className="mt-8" data-testid="table-employees-summary">
      <Table
        columns={columns}
        data={filteredData}
        isLoading={isLoading}
        emptyMessage="No organization data available"
        minColumnWidth="8rem" // Default minimum width for all columns
      />
    </div>
  );
};

export default TableEmployeesSummary;
