import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Sidebar from "../sidebar";
import Drawer from "../drawer";
import ProfileDrawer from "../profile-drawer";
import MenuBar from "../menu-bar";
import { useViewDasboardStore } from "../../storesZustand/useViewDasboardStore";

/**
 * Main Layout component for the application
 */
const Layout = ({
  children,
  user,
  dashboardData,
  showProfileDrawer = true,
}) => {
  const [isOpen, isOpen1, setIsOpen, setIsOpen1] = useViewDasboardStore(
    (state) => [state.isOpen, state.isOpen1, state.setIsOpen, state.setIsOpen1]
  );

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", updateScreenWidth);

    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    setIsOpen1(false);
  };

  const toggleProfileDrawer = () => {
    setIsOpen1(!isOpen1);
    setIsOpen(false);
  };

  return (
    <div className="w-[100%]" data-testid="layout">
      {/* Navigation Drawer */}
      <Drawer isOpen={isOpen} toggleDrawer={toggleDrawer} />

      {/* Profile Drawer - conditionally rendered */}
      {showProfileDrawer && (
        <ProfileDrawer
          isOpen1={isOpen1}
          toggleDrawer1={toggleProfileDrawer}
          data={dashboardData?.last3MonthsData}
        />
      )}

      {/* Sidebar for larger screens */}
      <div className="w-[250px] xl:flex hidden">
        <Sidebar />
      </div>

      {/* Main Content Area */}
      <div className="xl:w-[calc(100%-240px)] w-[100%] xl:ml-[240px] bg-[#F6F6F6] flex justify-between">
        {/* Page Content */}
        <div className="flex-grow w-full">{children}</div>

        {/* Menu Bar */}
        {user && <MenuBar user={user} screenWidth={screenWidth} />}
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.object,
  dashboardData: PropTypes.object,
  showProfileDrawer: PropTypes.bool,
};

export default Layout;
