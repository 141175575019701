import { useQuery } from "react-query";
import api from "../../utils/api";

export function useRestaurantStats(id) {
  const token = localStorage.getItem("token");

  return useQuery(
    ["restaurantStats1", id],
    async () => {
      if (!id) return [];
      if (token) {
        const response = await api.get("/location/restaurantStats/" + id, {
          headers: {
            "x-auth-token": token,
          },
        });
        return response?.data?.data;
      }
    },
    {
      cacheTime: 120000,
      staleTime: 100000,
    }
  );
}

export function useEmployeesForLocations(id) {
  const token = localStorage.getItem("token");
  return useQuery(
    ["employeesForLocations123", id],
    async () => {
      if (token) {
        if (!id) return [];
        const response = await api.post("/location/employeesForLocations/", {
          locations: id,
        });
        return response?.data?.data || [];
      }
    },
    {
      cacheTime: 120000,
      staleTime: 100000,
    }
  );
}
