import React, { useState } from "react";
import api from "../../../utils/api";
import { useUserStore } from "../../../storesZustand/useUserStore";
import { toast } from "react-toastify";

const ModalDeviceDetails = ({ isOpen, setIsOpen, deviceId }) => {
  const [usageData, setUsageData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [user] = useUserStore((state) => [state.user]);

  const allowedEmails = [
    "conordisigns@gmail.com",
    "martin.clowry@halcyongroup.ie",
    "john.coleman@halcyongroup.ie",
  ];

  const closeModal = () => {
    setIsOpen(false);
    setUsageData(null);
    setShowDeleteConfirm(false);
  };

  const fetchUsageData = async () => {
    setIsLoading(true);
    try {
      // Calculate timestamps for last 30 days
      const toEpoch = Math.floor(Date.now() / 1000);
      const fromEpoch = toEpoch - 30 * 24 * 60 * 60; // 30 days ago

      const queryParams = new URLSearchParams({
        device_ids: deviceId,
        from_dt: fromEpoch,
        to_dt: toEpoch,
        freq: "1d", // 1 day frequency
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone, // Get user's timezone
      });

      const response = await api.get(
        `/device/usage-time?${queryParams.toString()}`
      );

      // Sort the data by date and time in descending order
      if (response.data && response.data.data) {
        const deviceId = Object.keys(response.data.data)[0];
        const timeData = response.data.data[deviceId];

        const sortedData = Object.entries(timeData)
          .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
          .reduce((acc, [date, value]) => {
            acc[date] = value;
            return acc;
          }, {});

        setUsageData({
          ...response.data,
          data: {
            [deviceId]: sortedData,
          },
        });
      } else {
        setUsageData(response.data);
      }
    } catch (error) {
      toast.error("Failed to fetch usage data");
      console.error("Error fetching usage data:", error);
    }
    setIsLoading(false);
  };

  const handleDeleteData = async () => {
    if (!allowedEmails.includes(user.email.toLowerCase())) {
      toast.error("You don't have permission to delete data");
      return;
    }

    setIsDeleting(true);
    try {
      // Calculate timestamps for all time
      const toEpoch = Math.floor(Date.now() / 1000);
      const fromEpoch = 0; // From beginning of time

      await api.delete("/stats/raw-data", {
        params: {
          device_ids: deviceId,
          from_dt: fromEpoch,
          to_dt: toEpoch,
        },
      });
      toast.success("Device raw data deleted successfully");
      closeModal();
    } catch (error) {
      toast.error("Failed to delete device data");
      console.error("Error deleting device data:", error);
    }
    setIsDeleting(false);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-2xl">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Device Details</h2>
          <button
            onClick={closeModal}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div className="mb-4">
          <h3 className="font-semibold mb-2">Device ID: {deviceId}</h3>
        </div>

        <div className="mb-4">
          <button
            onClick={fetchUsageData}
            disabled={isLoading}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
          >
            {isLoading ? "Loading..." : "Get Usage Data (Last 30 Days)"}
          </button>
        </div>

        <div className="mb-4">
          <h3 className="font-semibold mb-2">Terminal Output:</h3>
          <div className="bg-gray-900 text-gray-100 p-4 rounded-lg font-mono text-sm overflow-auto max-h-60">
            <div className="flex items-center mb-2">
              <span className="text-green-400">$</span>
              <span className="ml-2">device-usage --device {deviceId}</span>
            </div>
            {isLoading ? (
              <div className="text-yellow-400">Fetching data...</div>
            ) : usageData ? (
              <pre className="whitespace-pre-wrap">
                {JSON.stringify(usageData.data || {}, null, 2)}
              </pre>
            ) : (
              <div className="text-gray-500">
                No data available. Click "Get Usage Data" to fetch.
              </div>
            )}
          </div>
        </div>

        {allowedEmails.includes(user.email.toLowerCase()) && (
          <div className="mt-4">
            {!showDeleteConfirm ? (
              <button
                onClick={() => setShowDeleteConfirm(true)}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              >
                Delete All Data
              </button>
            ) : (
              <div className="flex gap-2">
                <button
                  onClick={handleDeleteData}
                  disabled={isDeleting}
                  className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 disabled:bg-red-300"
                >
                  {isDeleting ? "Deleting..." : "Confirm Delete"}
                </button>
                <button
                  onClick={() => setShowDeleteConfirm(false)}
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalDeviceDetails;
