// src/components/Drawer.js
import React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProfilePicture from "../profile-picture";
import { formattedCurrency } from "../../helpers/helpers";
import { useUserStore } from "../../storesZustand/useUserStore";

const Drawer = ({ toggleDrawer1, isOpen1, data }) => {
  const [user] = useUserStore((state) => [state.user]);
  var mL = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so we add 1 and pad with '0' if necessary
  const currentMonthF = `${year}-${currentMonth}`;
  const prevMonth = String(currentDate.getMonth()).padStart(2, "0"); // Month is zero-based, so we add 1 and pad with '0' if necessary
  const prevMonthF = `${year}-${prevMonth}`;
  const twoMonthsAgo = String(currentDate.getMonth() - 1).padStart(2, "0"); // Month is zero-based, so we add 1 and pad with '0' if necessary
  const twoMonthsAgoF = `${year}-${twoMonthsAgo}`;

  const [imageRatio, setImageRatio] = useState(null);
  const [image, setImage] = useState("img");

  return (
    <div>
      {/* <button
        className="fixed top-0 right-0 m-4 p-2 bg-blue-500 text-white rounded-md"
        onClick={() => toggleDrawer()}
      >
        {isOpen ? "Close Drawer" : "Open Drawer"}
      </button> */}
      <div
        className={`fixed top-0 px-4 z-30 right-0 h-[100vh] overflow-y-scroll w-[320px] bg-white shadow-md transform transition-transform ${
          isOpen1 ? "translate-x-0" : "translate-x-[100%]"
        }`}
      >
        <div className="w-[280px]   pb-[100px] pt-[45px]">
          <div className="flex justify-end">
            <svg
              onClick={() => toggleDrawer1()}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <ProfilePicture user={user} />
          <p className="text-center text-[24px] font-bold mt-[25px] text-[#32383F]">
            {user?.first_name} {user?.last_name}
          </p>
          <p className="text-[#787878] text-center mt-2">{user?.email}</p>
          <div className="w-[156px] h-[52px] m-auto flex justify-center items-center edit-profile">
            <p className="text-[#112945] font-bold">Edit Profile</p>
          </div>
          <div className="training-box2 border m-auto mt-[50px]">
            <div className="bg-[white] rounded-full h-[44px] w-[44px] flex justify-center items-center">
              <p className="text-[20px] text-[#112944] font-bold">1</p>
            </div>
            <p className="text-[#C6C6C6] mt-2">
              Savings in {mL[currentDate.getMonth() - 2]}
            </p>
            <p className="text-[30px] font-bold  text-white">
              {" "}
              {formattedCurrency(data?.[twoMonthsAgoF])}
            </p>
            <div className="w-full bg-[#112844] rounded-full h-2.5">
              <div
                className="bg-[#E5A50E] h-2.5 rounded-full"
                style={{ width: "80%" }}
              ></div>
            </div>
          </div>
          <div className="training-box3 border m-auto mt-[20px]">
            <div className="bg-[#F4F4F4] rounded-full h-[44px] w-[44px] flex justify-center items-center">
              <p className="text-[20px] text-[#112944] font-bold">2</p>
            </div>
            <p className="text-[#C6C6C6] mt-2">
              Savings in {mL[currentDate.getMonth() - 1]}
            </p>
            <p className="text-[30px] font-bold  text-[#32383F]">
              {formattedCurrency(data?.[prevMonthF])}
            </p>
            <div className="w-full bg-[#E0E0E0] rounded-full h-2.5">
              <div
                className="bg-[#E5A50E] h-2.5 rounded-full"
                style={{ width: "50%" }}
              ></div>
            </div>
          </div>
          <div className="training-box3 border m-auto mt-[20px]">
            <div className="bg-[#F4F4F4] rounded-full h-[44px] w-[44px] flex justify-center items-center">
              <p className="text-[20px] text-[#112944] font-bold">3</p>
            </div>
            <p className="text-[#C6C6C6] mt-2">
              Savings in {mL[currentDate.getMonth()]}
            </p>
            <p className="text-[30px] font-bold  text-[#32383F]">
              {formattedCurrency(data?.[currentMonthF])}
            </p>
            <div className="w-full bg-[#E0E0E0] rounded-full h-2.5">
              <div
                className="bg-[#E5A50E] h-2.5 rounded-full"
                style={{ width: "50%" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
