import React, { useCallback, useEffect, useMemo, useState } from "react";
import { daysInARowCheck, formattedCurrency } from "../../helpers/helpers";
import CircleIcon from "../UI/CircleIcon";
import SortButton from "../sort-button";
import useSortData from "../../hooks/useSortData";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDevicesByOneLocation } from "../../services/devices/queries";
import { getPrevious7Days } from "../../helpers/getPrevious7Days";
import { listOfDates } from "../../utils/constans/listOfDates";
import { useViewDasboardStore } from "../../storesZustand/useViewDasboardStore";
import DropdownDays from "../dropdown-days";
import SearchBar from "../search-bar";
import StatBlock from "../stat-block/StatBlock";
import { locale } from "../../utils/constans/locaLanguage";
import Map from "../map";
import { useUserStore } from "../../storesZustand/useUserStore";
import Chart from "../UI/chart";
import BreadcrumbsComponent from "../UI/breadcrumbs";
import { useDeviceStore } from "../../storesZustand/useDeviceStore";
import { useLocationStore } from "../../storesZustand/useLocationStore";
import { useOrganizationStore } from "../../storesZustand/useOrganizationStore";
import { generateDaysOfWeek } from "../../helpers/iconsResultWorkersByDays";
import { ModalTypes } from "../all-modal-window/modal-window";
import {
  StatBlockSkeleton,
  ChartSkeleton,
  TableSkeleton,
} from "../UI/Skeleton";
import LoadingSpinner from "../LoadingSpinner";

const TableDeviceOneLocation = () => {
  const { organizationId, locationId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoading: isLoadingDeviceOneLocation, data: devicesOneLocation } =
    useDevicesByOneLocation(locationId);

  const [locations] = useLocationStore((state) => [state.locations]);
  const [organizations] = useOrganizationStore((state) => [
    state.organizations,
  ]);
  const [user] = useUserStore((state) => [state.user]);

  //The same Zustand store
  const [isOpen, isOpen1, setIsOpen, setIsOpen1] = useViewDasboardStore(
    (store) => [store.open1, store.open2, store.setIsOpen, store.setIsOpen1]
  );

  const [
    setDisplayModalWindowType,
    setCurrentOpenIdForEditModalWindow,
    setCurrentDawnloadExcelOrganizationIdForDevice,
  ] = useViewDasboardStore((state) => [
    state.setDisplayModalWindowType,
    state.setCurrentOpenIdForEditModalWindow,
    state.setCurrentDawnloadExcelOrganizationIdForDevice,
  ]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedOption, setSelectedOption] = useState(listOfDates[0].name);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isOpen]);

  const dataWithSorting = useMemo(() => {
    if (devicesOneLocation?.devicesWithAnalyticsByDays) {
      let devices = devicesOneLocation.devicesWithAnalyticsByDays;
      devices.sort((a, b) => b.savings - a.savings);
      return devices;
    }
  }, [devicesOneLocation]);

  const { sortedData, sortData } = useSortData(dataWithSorting);

  const handlerCickButtonForOpenEditModal = (id, typeModalWindow) => {
    setDisplayModalWindowType(typeModalWindow);
    setCurrentOpenIdForEditModalWindow(id);
  };

  const handlerCickButtonDawnloadData = (
    id,
    organizationId,
    typeModalWindow
  ) => {
    setDisplayModalWindowType(typeModalWindow);
    setCurrentOpenIdForEditModalWindow(id);
    setCurrentDawnloadExcelOrganizationIdForDevice(organizationId);
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    setIsOpen1(false);
  };

  const findLocation = useMemo(() => {
    if (locations) {
      return locations.find((item) => item.id === +locationId);
    }
  }, [locationId, locations]);

  const sumObjectValues = useCallback((obj) => {
    return Object.values(obj).reduce((sum, value) => sum + value, 0);
  }, []);

  const daysOfWeek = useMemo(() => {
    return generateDaysOfWeek();
  }, []);

  const checkCondition = useCallback(
    (day, item) => {
      return daysInARowCheck(day, item, daysOfWeek);
    },
    [daysOfWeek]
  );

  const openModal = (modalType) => {
    setDisplayModalWindowType(modalType);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter the sorted data based on the search query
  const filteredData = sortedData?.filter((item) =>
    Object.values(item).some((value) =>
      value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  // Determine if data is ready to display
  const isDataReady =
    !isLoadingDeviceOneLocation && devicesOneLocation && sortedData;

  return isLoadingDeviceOneLocation ? (
    <div className="flex justify-center items-center w-full h-screen">
      <LoadingSpinner isFullPage={false} />
    </div>
  ) : (
    <div className="w-[100%] h-full sm:p-10 p-4 pt-[100px] sm:pr-[120px] sm:pt-[25px]">
      <div
        className={`w-full h-[40px] flex flex-row justify-between items-center py-2 px-2 ${
          screenWidth <= 640 || screenWidth >= 1025 ? "hidden" : "block"
        }`}
      >
        <button onClick={() => toggleDrawer()}>
          <img src="/images/burger-open.svg" alt="burger" />
        </button>
      </div>
      {screenWidth < 640 && (
        <div className="fixed z-30 top-0 left-0 w-full h-[88px] bg-white flex flex-row justify-between items-center py-7 px-5">
          <button onClick={() => toggleDrawer()}>
            <img src="/images/burger-open.svg" alt="burger" />
          </button>
          {/* <DropdownDays
            screenWidth={screenWidth}
            data={listOfDates}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            // optionDefault={listOfDates[0].name}
          /> */}
        </div>
      )}
      <div className="flex justify-between mb-4 items-center">
        <div className="flex items-start gap-2">
          {user && organizations && findLocation && (
            <BreadcrumbsComponent
              breadCrumbsArr={
                user.organization_id === 1 && user.role_id === 3
                  ? [
                      { name: "Overview", url: "" },
                      {
                        name: user.organization_name,
                        url: "/dashboard/organizations",
                      },
                      {
                        name: organizations.find(
                          (item) => item.id === +organizationId
                        ).name,
                        url: `/dashboard/organizations/${organizationId}`,
                      },
                      {
                        name: findLocation.name,
                        url: location.pathname,
                      },
                    ]
                  : [
                      { name: "Overview", url: "" },
                      {
                        name: user.organization_name,
                        url: "/dashboard/locations",
                      },
                      {
                        name: findLocation.name,
                        url: location.pathname,
                      },
                    ]
              }
            />
          )}
        </div>
        <div className="flex items-start gap-2">
          {screenWidth >= 640 && (
            <SearchBar
              placeholder="Type to search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          )}
          {/* {screenWidth >= 640 && (
            <DropdownDays
              screenWidth={screenWidth}
              data={listOfDates}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              // optionDefault={listOfDates[0].name}
            />
          )} */}
        </div>
      </div>
      <div className="w-full h-auto md:h-[320px] flex flex-col md:flex-row overflow-hidden">
        <div className="w-full md:flex-[2] h-full">
          <div className="flex flex-col w-full h-full">
            <div className="flex flex-col md:flex-row w-full h-1/2">
              <div className="w-full md:flex-1 p-1">
                {isDataReady ? (
                  <StatBlock
                    variant="dark"
                    icon="/images/Frame (10).svg"
                    description="Total Savings"
                    amount={
                      devicesOneLocation?.totalLitres
                        ? formattedCurrency(
                            devicesOneLocation?.totalLitres *
                              devicesOneLocation?.oilCostPerLitre,
                            devicesOneLocation?.currency
                          )
                        : "0"
                    }
                    unit="€"
                  />
                ) : (
                  <StatBlockSkeleton variant="dark" />
                )}
              </div>
              <div className="w-full md:flex-1 p-1">
                {isDataReady ? (
                  <StatBlock
                    variant="light"
                    icon="/images/carbon-footprint.svg"
                    description="Carbon Saved"
                    amount={
                      devicesOneLocation?.totalLitres
                        ? parseInt(
                            devicesOneLocation.totalLitres * 3.81
                          ).toLocaleString(locale)
                        : "0"
                    }
                    unit="kg CO2e"
                  />
                ) : (
                  <StatBlockSkeleton />
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full h-1/2">
              <div className="w-full md:flex-1 p-1">
                {isDataReady ? (
                  <StatBlock
                    variant="light"
                    icon="/images/Frame (12).svg"
                    description="Savings last 30 Days"
                    amount={
                      devicesOneLocation?.totalLitresLast30Days
                        ? formattedCurrency(
                            devicesOneLocation?.totalLitresLast30Days *
                              devicesOneLocation?.oilCostPerLitre,
                            devicesOneLocation?.currency
                          )
                        : "0"
                    }
                    unit="€"
                  />
                ) : (
                  <StatBlockSkeleton />
                )}
              </div>
              <div className="w-full md:flex-1 p-1">
                {isDataReady ? (
                  <StatBlock
                    variant="light"
                    icon="/images/Frame (12).svg"
                    description="Litres of oil saved"
                    amount={
                      devicesOneLocation?.totalLitres
                        ? parseInt(
                            devicesOneLocation?.totalLitres
                          ).toLocaleString(locale) + " L"
                        : "0"
                    }
                    unit="L"
                  />
                ) : (
                  <StatBlockSkeleton variant="litres" />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:flex-[4] h-[300px] md:h-full p-1 min-w-0">
          {isDataReady ? (
            <Chart
              variant="light"
              data={
                devicesOneLocation?.last7daysData
                  ? devicesOneLocation?.last7daysData
                  : {}
              }
              costPerLitre={devicesOneLocation?.oilCostPerLitre}
              screenWidth={screenWidth}
            />
          ) : (
            <ChartSkeleton />
          )}
        </div>
      </div>
      <div className="flex gap-8 flex-col mt-8 sm:flex-row">
        {screenWidth < 640 && (
          <SearchBar
            placeholder="Type to search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        )}
      </div>
      <div className="table-container overflow-auto w-full">
        {isDataReady ? (
          <table className="divide-y-8 divide-[#F4F4F4] w-full">
            <thead>
              <tr>
                <th className="whitespace-nowrap flex items-center gap-2 px-6 py-2 text-left md:text-base text-sm font-normal text-[#B0CAD1] tracking-wider">
                  <p>Device Name</p>
                  <SortButton
                    handlerToggler={sortData}
                    text="A - Z"
                    sortBy="location_name"
                  />
                </th>
                <th className="px-6 py-2 text-left md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider">
                  Serial No.
                </th>
                <th className="whitespace-nowrap px-6 py-2  md:text-base  text-[#B0CAD1] text-sm font-normal  tracking-wider text-center">
                  Total Savings
                </th>
                <th className="pl-6 py-2 flex justify-between md:text-base text-[#B0CAD1] text-sm font-normal tracking-wider text-center">
                  {getPrevious7Days().map((day, i) => {
                    return <div key={i}>{day}</div>;
                  })}
                </th>
                <th className="whitespace-nowrap px-6 py-2  md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider text-center">
                  Last 7 Days Savings
                </th>
                <th className="px-6 py-2 md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider text-center">
                  Reports
                </th>
              </tr>
            </thead>
            <tbody className="divide-y-8 divide-[#F4F4F4]">
              {filteredData?.map((item) => (
                <tr className="bg-white rounded-lg" key={item?.id}>
                  <td className="px-6 py-3 whitespace-nowrap rounded-l-full">
                    <div className="flex gap-3">
                      <img
                        className="cursor-pointer"
                        onClick={() =>
                          handlerCickButtonForOpenEditModal(
                            item.id,
                            ModalTypes.EDIT_DEVICE
                          )
                        }
                        src="/images/pencil.svg"
                        alt=""
                      />
                      <div className="text-base font-medium text-[#1E3A56]">
                        {item?.name}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="flex gap-3">
                      <div className="text-base text-[#1E3A56]">{item?.id}</div>
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="text-base text-[#1E3A56] text-center">
                      {formattedCurrency(
                        item?.savings
                          ? item.savings * devicesOneLocation?.oilCostPerLitre
                          : 0,
                        devicesOneLocation?.currency
                      )}
                    </div>
                  </td>
                  <td className="pl-6 py-3 whitespace-nowrap">
                    <div className="flex justify-between text-[#1E3A56] h-6">
                      {Object.keys(generateDaysOfWeek())
                        .map((day) => {
                          return checkCondition(day, item);
                        })
                        .map((condition, index) => (
                          <div key={index} className="w[22px]">
                            <CircleIcon condition={condition} />
                          </div>
                        ))}
                    </div>
                  </td>
                  <td className="lg:px-6 px-20 py-3 whitespace-nowrap">
                    <div className="text-base text-[#1E3A56] text-center">
                      {formattedCurrency(
                        sumObjectValues(
                          item?.dataEachLocationByDaysInLiters || 0
                        ) * devicesOneLocation?.oilCostPerLitre,
                        devicesOneLocation?.currency
                      )}
                    </div>
                  </td>
                  <td className="lg:px-6 px-20 py-3 whitespace-nowrap rounded-r-full">
                    <div className="text-base text-[#1E3A56] text-center flex justify-center">
                      <button
                        onClick={() =>
                          handlerCickButtonDawnloadData(
                            item.id,
                            organizationId,
                            ModalTypes.DOWNLOAD_DEVICE_DATA
                          )
                        }
                        className="w-[80px] h-[30px]  flex justify-center items-center font-ABeeZee text-xs font-normal text-white bg-[#8597A8] border border-[#8597A8] rounded-[7px] py-3 px-6"
                      >
                        Download
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <TableSkeleton
            rows={5}
            columns={4}
            headers={[
              "Device Name",
              "Serial Number",
              "Total Savings",
              "Reports",
            ]}
          />
        )}
        {sortedData?.length > 0 ? null : (
          <>
            <p className="text-center mt-5 mb-3 text-[#969899]">
              No Devices Detected
            </p>
            <div
              className="flex items-center flex-col justify-center gap-1 cursor-pointer"
              onClick={() => openModal("Add Device")}
            >
              <div>
                <img
                  src={"/images/addDeviceIcon.svg"}
                  alt=""
                  className="w-5 h-5"
                />
              </div>
              <div className="text-[#8597A8] text-[10px] text-center">
                Add Device
              </div>
            </div>
          </>
        )}
      </div>
      <div>
        {findLocation && sortedData?.length > 0 ? (
          <Map devices={[findLocation]} />
        ) : null}
      </div>
    </div>
  );
};

export default TableDeviceOneLocation;
