import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import api from "../../utils/api";
import { toast } from "react-toastify";
import { useUserStore } from "../../storesZustand/useUserStore";
import BackButton from "../../components/back-button";

const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://api.halcyon-eco.com"
    : "http://localhost:3900";

const Authentication = ({ inviteCode }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errormsg, setErrorMsg] = useState("");
  const token = localStorage.getItem("token");
  const [user, setUser] = useUserStore((state) => [
    state.user,
    state.setUsersDataAfterLogin,
  ]);

  const role = user?.role;

  // Read the existing data from the JSON file

  // Add the new user data to the array

  // Write the updated array back to the JSON file

  const handleSignUp = () => {
    navigate("/");
  };
  const [show, setShow] = useState(false);

  const handleToggle = () => {
    setShow(!show);
  };
  const validationSchema = Yup.object().shape({
    agreeToTerms: Yup.boolean()
      .oneOf(
        [true],
        "You must agree to the terms of service and privacy policy"
      )
      .required("You must agree to the terms of service and privacy policy"),
    firstName: Yup.string()
      .min(1, "First Name must be at least 1 character")
      .required("First Name is required"),
    lastName: Yup.string()
      .min(1, "Last Name must be at least 1 character")
      .required("Last Name is required"),
    signUpCode: Yup.string()
      .min(5, "Sign Up Code must be at least 6 characters")
      .required("Sign Up Code is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      // .matches(
      //   /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      //   "Password must contain at least one letter, one number, and one special character"
      // )
      .required("Password is required"),
  });
  const handleSubmit = async (values, { setSubmitting }) => {
    setIsLoading(true);

    const { firstName, lastName, email, password, signUpCode } = values;

    axios
      .post(`${baseURL}/user/signUp`, {
        first_name: firstName,
        last_name: lastName,
        email: email.toLowerCase(),
        password,
        code: signUpCode,
      })
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          setSubmitting(false);

          // Store tokens
          localStorage.setItem("token", res?.data?.tokens?.idToken);
          localStorage.setItem("refreshToken", res?.data?.tokens?.refreshToken);

          // Store user data in the store
          const userData = res.data.data;
          setUser(userData);
          useUserStore.getState().setUsersDataAfterLogin(userData);

          // Redirect based on role
          switch (userData.role_id) {
            case 1: // Admin
              navigate("/training");
              break;
            case 2: // Employee
              navigate("/training-portal");
              break;
            case 3: // Super Admin
              navigate("/dashboard/organizations");
              break;
            default:
              navigate("/");
          }
        }
      })
      .catch((res) => {
        setIsLoading(false);
        setSubmitting(false);
        if (res?.response?.status !== 401)
          toast.error(res?.response?.data?.message);
      });
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      showPassword: false,
      agreeToTerms: false,
      signUpCode: inviteCode || "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  if (token) {
    if (role === "employee") return <Navigate to="/training-portal" replace />;

    if (role === "admin") {
      return <Navigate to="/training" />;
    }

    if (role === "superadmin") {
      return <Navigate to="/super-admin" />;
    }
  }

  return (
    <React.Fragment>
      <BackButton />
      <div className="bg-[#040824] w-full min-h-[100vh] overflow-scroll my-scroller text-white">
        <div className="max-w-[400px] w-[100%] m-auto sm:pt-[50px] pt-[10px] px-[10px]">
          <img
            src="/images/halcyon-logo-glow.svg"
            alt="Halcyon Logo"
            width="170" // Set the width of the logo
            height="50" // Set the height of the logo
            className="mx-auto lg:hidden block"
          />
          <img src="/images/Rectangle.png" alt="" />
          <p className="text-[45px] font-bold mt-3">Register </p>
          <p className="text-[20px] font-normal mt-[5px] tracking-[0.094px]">
            To register for our training section you will be required to enter
            the unique business code that can be created in the business admin
            account.
          </p>
          <form onSubmit={formik.handleSubmit}>
            <div className="form mt-5 ">
              <div className="relative mt-4">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-7 h-7 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      stroke-linejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                    />
                  </svg>
                </span>
                <input
                  className="pl-12 w-full pr-3 py-2 bg-[#2A2E45] h-[56px] rounded border border-gray-300  focus:outline-none"
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.touched.firstName && formik.errors.firstName && (
                <div className="text-red-500 mt-1 text-[12px]">
                  {formik.errors.firstName}
                </div>
              )}
              <div className="relative mt-4">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-7 h-7 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                    />
                  </svg>
                </span>
                <input
                  className="pl-12 w-full pr-3 py-2 bg-[#2A2E45] h-[56px] rounded border border-gray-300  focus:outline-none"
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.touched.lastName && formik.errors.lastName && (
                <div className="text-red-500 mt-1 text-[12px]">
                  {formik.errors.lastName}
                </div>
              )}
              <div className="relative mt-4">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-7 h-7 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                    />
                  </svg>
                </span>
                <input
                  className="pl-12 w-full pr-3 py-2 bg-[#2A2E45] h-[56px] rounded border border-gray-300  focus:outline-none"
                  type="text"
                  name="email"
                  placeholder="johan@gmail.com"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.touched.email && formik.errors.email && (
                <div className="text-red-500 mt-1 text-[12px]">
                  {formik.errors.email}
                </div>
              )}

              {formik.touched.signUpCode && formik.errors.signUpCode && (
                <div className="text-red-500 mt-1 text-[12px]">
                  {formik.errors.signUpCode}
                </div>
              )}

              <div className="relative mt-4">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-7 h-7 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                    />
                  </svg>
                </span>
                <input
                  className="pl-12 w-full pr-3 py-2 bg-[#2A2E45] h-[56px] rounded border border-gray-300  focus:outline-none"
                  type={show ? "text" : "password"}
                  placeholder="***********"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  autoComplete="new-password"
                />
                {show ? (
                  <svg
                    onClick={handleToggle}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-4 h-4 absolute right-3 top-5 cursor-pointer"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                ) : (
                  <svg
                    onClick={handleToggle}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-4 h-4 absolute right-3 top-5 cursor-pointer"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                    />
                  </svg>
                )}
              </div>
              {formik.touched.password && formik.errors.password && (
                <div className="text-red-500 mt-1 text-[12px]">
                  {formik.errors.password}
                </div>
              )}
            </div>
            <div className="flex items-center mt-3">
              <input
                type="checkbox"
                name="agreeToTerms"
                checked={formik.values.agreeToTerms}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label className="ml-2 text-sm">
                I agree to the
                <Link
                  to="/terms-of-service"
                  className="text-white hover:underline ml-1"
                >
                  Terms of Service
                </Link>
                {" and"}
                <Link
                  to="/privacy-policy"
                  className="text-white hover:underline ml-1"
                >
                  Privacy Policy
                </Link>
                .
              </label>
            </div>
            {formik.touched.agreeToTerms && formik.errors.agreeToTerms && (
              <div className="text-red-500 mt-1 text-[12px]">
                {formik.errors.agreeToTerms}
              </div>
            )}
            <div>
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className="register-button"
              >
                {isLoading ? (
                  <div role="status" className="mx-auto w-[30px]">
                    <svg
                      aria-hidden="true"
                      className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-[#E4A61A]"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                ) : (
                  <span>REGISTER</span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Authentication;
