import React, { useMemo } from "react";
import PropTypes from "prop-types";

/**
 * StatBlock component for displaying statistics
 */
function StatBlock({
  unit,
  icon,
  description,
  amount,
  variant,
  className = "",
  style = {},
}) {
  const variantClass = useMemo(() => {
    return variant === "dark" ? "training-box2" : "training-box3";
  }, [variant]);

  return (
    <div
      className={`${variantClass} bg-white relative w-full h-full ${className}`}
      style={{
        padding: "1.25rem",
        borderRadius: "0.75rem",
        display: "flex",
        flexDirection: "column",
        ...style,
      }}
    >
      <div
        className={`absolute top-6 right-6 text-${
          variant === "dark" ? "white" : "#32383F"
        } text-2xl opacity-80`}
      >
        ℮
      </div>
      <div
        className={`${
          variant === "dark" ? "bg-[white]" : "bg-gray-200"
        } rounded-full h-[44px] w-[44px] flex justify-center items-center`}
      >
        <img src={icon} alt="" />
      </div>
      <p
        className={`text-[${
          variant === "dark" ? "#C6C6C6" : "#787878"
        }] mt-2 text-sm`}
      >
        {description}
      </p>
      <div className="flex flex-row items-end mt-auto">
        <p
          className={`font-bold ${
            variant === "dark" ? "text-white" : "text-[#32383F]"
          } text-2xl`}
        >
          {amount}
        </p>
        {unit === "kg CO2e" ? (
          <span className="ml-2 text-sm">kg CO2e</span>
        ) : null}
      </div>
    </div>
  );
}

StatBlock.propTypes = {
  unit: PropTypes.string,
  icon: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(["dark", "light"]).isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default StatBlock;
