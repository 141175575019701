import React, { useEffect, useMemo, useRef } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: false,
      text: "Savings",
    },
  },
  scales: {
    x: {
      title: {
        display: true,
      },
      grid: {
        display: true,
      },
    },
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Savings - €",
      },
      grid: {
        display: true,
      },
    },
  },
};

export const Chart = ({ data, variant, screenWidth, costPerLitre = 1.19 }) => {
  const chartRef = useRef(null);
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const today = new Date();

  const chartData = useMemo(() => {
    // Handle case when data is not provided or is not in the expected format
    if (!data) return null;

    // Check if data is an array (expected format from the component update)
    if (Array.isArray(data)) {
      return {
        labels: data.map((item) => item.day || ""),
        datasets: [
          {
            label: "Savings",
            data: data.map((item) => item.savings || 0),
            borderColor: "#E5A50E",
            backgroundColor: "#E5A50E",
            tension: 0.4,
          },
        ],
      };
    }
    // Handle the original format (object with date keys)
    else if (typeof data === "object" && !Array.isArray(data)) {
      // Generate labels for the last 7 days
      const labels = [];
      for (let i = 7; i > 0; i--) {
        const date = new Date(today);
        date.setDate(today.getDate() - i);
        const dayName = daysOfWeek[date.getDay()];
        labels.push(dayName);
      }

      // Extract data for the last 7 days
      const values = [];
      for (let i = 6; i >= 0; i--) {
        const date = new Date(today);
        date.setDate(today.getDate() - i - 1);
        const dateString = date.toISOString().split("T")[0];
        const value = data[dateString] || 0;
        values.push(typeof value === "number" ? value.toFixed(2) : 0);
      }

      return {
        labels,
        datasets: [
          {
            label: "Savings",
            data: values,
            borderColor: "#E5A50E",
            backgroundColor: "#E5A50E",
            tension: 0.4,
          },
        ],
      };
    }
    // Fallback for string or other unexpected formats
    else {
      return {
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
          {
            label: "Savings",
            data: [0, 0, 0, 0, 0, 0, 0],
            borderColor: "#E5A50E",
            backgroundColor: "#E5A50E",
            tension: 0.4,
          },
        ],
      };
    }
  }, [data, today]);

  const chartOptions = useMemo(() => {
    return {
      ...options,
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        ...options.scales,
        x: {
          ...options.scales.x,
          ticks: {
            font: {
              size: screenWidth < 640 ? 10 : 12,
            },
          },
        },
        y: {
          ...options.scales.y,
          ticks: {
            font: {
              size: screenWidth < 640 ? 10 : 12,
            },
          },
        },
      },
    };
  }, [screenWidth]);

  return (
    <div
      className={`${
        variant === "dark" ? "training-box2" : "training-box3"
      } bg-white w-full h-full`}
      style={{
        padding: "1.25rem",
        borderRadius: "0.75rem",
        display: "flex",
        flexDirection: "column",
        height: screenWidth < 640 ? "300px" : "100%",
      }}
    >
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-[#32383F] font-bold">Savings</h3>
      </div>
      <div
        className="flex-grow"
        style={{ minHeight: screenWidth < 640 ? "250px" : "200px" }}
      >
        {chartData && (
          <Line ref={chartRef} options={chartOptions} data={chartData} />
        )}
      </div>
    </div>
  );
};

export default Chart;
