import React, { useState } from "react";
import PropTypes from "prop-types";

/**
 * SortButton component for table column sorting
 *
 * This component displays a button that toggles between ascending and descending sort order
 * and shows the current sort direction.
 */
const SortButton = ({ handlerToggler, text, sortBy }) => {
  const [sortOrder, setSortOrder] = useState("asc");

  const handleClick = () => {
    // Toggle sort order
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);

    // Call the handler with the sort field and direction
    handlerToggler(sortBy, newSortOrder);
  };

  return (
    <button
      className="bg-[#8597A8] px-3 py-1 rounded-lg h-full"
      onClick={handleClick}
    >
      <div className="flex items-center justify-center gap-2.5 h-full">
        <span className="text-white text-[11px] leading-6 whitespace-nowrap">
          {sortOrder === "asc" ? "A - Z" : "Z - A"}
        </span>
        <div className="h-[15px] w-[8px]">
          <img src="/images/up-down-arrow.svg" alt="arrows" />
        </div>
      </div>
    </button>
  );
};

SortButton.propTypes = {
  handlerToggler: PropTypes.func.isRequired,
  text: PropTypes.string,
  sortBy: PropTypes.string.isRequired,
};

export default SortButton;
