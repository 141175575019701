import React from "react";

const LoadingSpinner = ({ isFullPage }) => {
  return (
    <div
      className={`flex flex-1 items-center justify-center ${
        isFullPage ? "fixed inset-0 bg-white" : ""
      }`}
    >
      <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-[#e5a50e]"></div>
    </div>
  );
};

export default LoadingSpinner;
