/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, Suspense, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Register from "./pages/register";
import Login from "./pages/login";
import Training from "./pages/training-admin";
import TrainingPortal from "./pages/training-portal";
import Users from "./pages/users";
import LoadingSpinner from "./components/LoadingSpinner";

import ExamTrainingPortal from "./pages/exam-training-portal";
import {
  ProtectedRouteMiddleware,
  ProtectedRouteMiddlewareSuperAdminHalcyonEco,
} from "./components/auth-gaurd";
import VerifyCode from "./pages/verifycode";
import Settings from "./pages/settings";
import ResetPassword from "./pages/resetpassword";
import VerifyActiveCodeAuth from "./views/verifycodeauth";
import Devices from "./pages/devices";
import Dashboard from "./pages/dashboard/Dashboard";

import { useUserData } from "./services/user/queries";
import TableEmployeesSummary from "./components/tables/TableEmployeesSummary";
import TableOrganisations from "./components/table-organisations";
import TableLocation from "./components/table-location";
import TableDeviceOneLocation from "./components/table-devices-one-location";
import TableOrganizationSuperAdmin from "./components/table-organizations-superAdmin";
import ModalWindow from "./components/all-modal-window/modal-window";
import TableLocations from "./components/table-locations-superAdmin";
import { useUserStore } from "./storesZustand/useUserStore";
import PrivacyPolicy from "./pages/privacy-policy";
import TermsOfService from "./pages/terms-of-service";
import { Amplify } from "aws-amplify";
import api from "./utils/api";
import Usage from "./pages/usage";
import { refreshTokens } from "./utils/api";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");
  const { data: userData, error: errorUserData } = useUserData();
  const [user] = useUserStore((state) => [state.user]);

  useEffect(() => {
    if (!token) {
      setIsLoading(false);
      return;
    }

    if (
      errorUserData?.response?.status === 401 ||
      errorUserData?.response?.data?.name === "TokenExpiredError" ||
      errorUserData?.response?.data?.code === "NotAuthorizedException"
    ) {
      if (refreshToken) {
        refreshTokens(refreshToken)
          .then(() => {
            setIsLoading(false);
          })
          .catch(() => {
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
            window.location.href = "/login";
          });
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        window.location.href = "/login";
      }
      return;
    }

    if (userData !== undefined || errorUserData) {
      setIsLoading(false);
    }
  }, [userData, errorUserData, token, refreshToken]);

  useEffect(() => {
    const fcmToken = window?.FCM_TOKEN;

    if (token && user && fcmToken && user?.fcmtoken !== fcmToken) {
      try {
        api.post(
          "/user/addFCMToken",
          {
            fcmToken,
          },
          {
            headers: {
              "x-auth-token": token,
            },
          }
        );
      } catch (error) {
        console.error("Error adding FCM token:", error);
      }
    }
  }, [user]);

  if (isLoading) {
    return <LoadingSpinner isFullPage={true} />;
  }

  return (
    <React.Fragment>
      <ModalWindow />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/register/:inviteCode" element={<Register />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verifycode" element={<VerifyCode />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/verifyactivestatuscode"
            element={<VerifyActiveCodeAuth />}
          />

          <Route element={<ProtectedRouteMiddleware />}>
            <Route path="/usage" element={<Usage />} />
            <Route path="/training" element={<Training />}>
              <Route index element={<TableEmployeesSummary />} />
              <Route
                path="org/:organization_id"
                element={<TableOrganisations />}
              />
              <Route
                path="org/:organization_id/loc/:location_id/"
                element={<TableLocation />}
              />
            </Route>
            <Route path="/training-portal" element={<TrainingPortal />} />
            <Route path="/shop" element={<></>} />

            <Route
              path="/exam-training-portal"
              element={<ExamTrainingPortal />}
            />
            <Route path="/settings" element={<Settings />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/invites" element={<Users />} />

            <Route path="/dashboard" element={<Dashboard />}>
              <Route path="locations" element={<TableLocations />} />
              <Route
                path="organizations/:organizationId/locations/:locationId"
                element={<TableDeviceOneLocation />}
              />
              <Route element={<ProtectedRouteMiddlewareSuperAdminHalcyonEco />}>
                <Route
                  exact
                  path="organizations"
                  element={<TableOrganizationSuperAdmin />}
                />
                <Route
                  exact
                  path="organizations/:organizationId"
                  element={<TableLocations />}
                />
              </Route>
            </Route>
          </Route>
          <Route element={<ProtectedRouteMiddlewareSuperAdminHalcyonEco />}>
            <Route path="/devices" element={<Devices />} />
          </Route>
          <Route path="*" element={<Login />} />
        </Routes>
      </Router>
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
