import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Layout } from "../../components/layout";
import { useDashboardDataStore } from "../../storesZustand/useDashboardDataStore";
import { useUserStore } from "../../storesZustand/useUserStore";
import { useOrganizationData } from "../../services/organization/queries";
import { useLocationData } from "../../services/location/queries";
import { useGetAllDevicesData } from "../../services/devices/queries";

/**
 * Dashboard page component
 */
const Dashboard = () => {
  const navigate = useNavigate();

  // Fetch data
  const { error: errorOrganization } = useOrganizationData();
  const { error: errorLocation } = useLocationData();
  const { error: errorAllDevicesData } = useGetAllDevicesData();

  // Get user and dashboard data from stores
  const [user] = useUserStore((state) => [state.user]);
  const [dashboardData] = useDashboardDataStore((state) => [
    state.devicesWithLocationWithAnalytics,
  ]);

  // Handle errors
  useEffect(() => {
    if (errorOrganization || errorLocation || errorAllDevicesData) {
      console.error("Error loading dashboard data:", {
        organizationError: errorOrganization,
        locationError: errorLocation,
        devicesError: errorAllDevicesData,
      });
    }
  }, [errorOrganization, errorLocation, errorAllDevicesData]);

  return (
    <Layout
      user={user}
      dashboardData={dashboardData}
      data-testid="dashboard-page"
    >
      <Outlet />
    </Layout>
  );
};

export default Dashboard;
