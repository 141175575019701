import React, { useState, useEffect } from "react";
import { useUserStore } from "../../storesZustand/useUserStore";
import { useOrganizationStore } from "../../storesZustand/useOrganizationStore";
import { useLocationStore } from "../../storesZustand/useLocationStore";
import Layout from "../../components/layout/Layout";
import SearchBar from "../../components/search-bar";
import Table from "../../components/tables/Table";
import api from "../../utils/api";
import { toast } from "react-toastify";
import ModalSelect from "../../components/UI/modal-select";
import ModalSelectWithCheckBox from "../../components/UI/modal-selectWithChackBox";
import { useViewDasboardStore } from "../../storesZustand/useViewDasboardStore";
import BreadcrumbsComponent from "../../components/UI/breadcrumbs";
import { useLocationData } from "../../services/location/queries";
import { useOrganizationData } from "../../services/organization/queries";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";

const Users = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user] = useUserStore((state) => [state.user]);
  const [organizations] = useOrganizationStore((state) => [
    state.organizations,
  ]);
  const [locations, fetchLocations] = useLocationStore((state) => [
    state.locations,
    state.fetchLocations,
  ]);

  useLocationData();
  useOrganizationData();

  const [isOpen, setIsOpen] = useViewDasboardStore((state) => [
    state.isOpen,
    state.setIsOpen,
  ]);
  const [activeTab, setActiveTab] = useState(
    location.pathname === "/users/invites" ? "invites" : "users"
  );
  const [users, setUsers] = useState([]);
  const [invites, setInvites] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingSaveUser, setLoadingSaveUser] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [showLocations, setShowLocations] = useState(true);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    role_id: "",
    organization_id: "",
    location_ids: [],
    isactive: false,
  });

  // Define breadcrumbs array
  const breadCrumbsArr = [
    { name: "Home", url: "/" },
    { name: "User Management", url: "/users" },
  ];

  // Create formik-like object for the modal selects
  const formik = {
    values: formData,
    setFieldValue: (field, value) => {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
    },
    errors: {},
    touched: {},
  };

  // Function to handle searching for a user by email
  const handleSearchUser = async () => {
    if (!searchQuery.trim()) {
      toast.error("Please enter an email to search");
      return;
    }

    setIsLoading(true);
    try {
      // Convert email to lowercase for consistent searching
      const email = searchQuery.trim().toLowerCase();
      const response = await api.get(`/user/getUserByEmail/${email}`);
      if (response.data && response.data.data) {
        setUsers(response.data.data);
      } else {
        setUsers([]);
        toast.info("No user found with that email");
      }
    } catch (error) {
      console.error("Error searching for user:", error);
      toast.error(error?.response?.data?.message || "Error searching for user");
      setUsers([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to fetch invites
  const fetchInvites = async () => {
    setIsLoading(true);
    try {
      const response = await api.get("/invites/getAllWithAccessControl");
      if (response.data && response.data.data) {
        setInvites(response.data.data);
      } else {
        setInvites([]);
      }
    } catch (error) {
      console.error("Error fetching invites:", error);
      toast.error(error?.response?.data?.message || "Error fetching invites");
      setInvites([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === "invites") {
      navigate("/users/invites");
    } else {
      navigate("/users");
    }
  };

  // Load invites when the component mounts or when the active tab changes to invites
  useEffect(() => {
    if (activeTab === "invites") {
      fetchInvites();
    }
  }, [activeTab]);

  // Initialize formik with the current values when the modal opens
  useEffect(() => {
    if (showEditModal && editingUser) {
      // Set the role value in the formik object
      if (formData.role_id) {
        formik.values.role_id = String(formData.role_id);
      }

      // Set the organization value in the formik object
      if (formData.organization_id) {
        formik.values.organization_id = String(formData.organization_id);

        // Filter locations based on the selected organization
        const orgLocations =
          locations?.filter(
            (loc) => loc.organization_id === parseInt(formData.organization_id)
          ) || [];

        setFilteredLocations(orgLocations);
      }

      // Set the location values in the formik object
      if (
        formData.location_ids &&
        Array.isArray(formData.location_ids) &&
        formData.location_ids.length > 0
      ) {
        const locationIdsAsStrings = formData.location_ids
          .filter((id) => id !== null && id !== undefined)
          .map((id) => String(id));

        formik.values.location_ids = locationIdsAsStrings;
      } else {
        formik.values.location_ids = [];
      }
    }
  }, [showEditModal, editingUser, formData, locations]);

  // Handle edit user/invite
  const handleEdit = (item) => {
    setEditingUser(item);

    // Convert location_name to location_ids if it exists
    let locationIds = [];
    if (item.location_ids && Array.isArray(item.location_ids)) {
      locationIds = item.location_ids.filter(
        (id) => id !== null && id !== undefined
      );
    } else if (item.location_id && item.location_id !== null) {
      locationIds = [item.location_id];
    }

    // Find the organization object based on the organization name or ID
    let organizationId = item.organization_id || "";
    if (!organizationId && item.organization) {
      // Try to find the organization by name
      const org = organizations.find((org) => org.name === item.organization);
      if (org) {
        organizationId = org.id;
      }
    }

    // Set form data with all the user details
    const updatedFormData = {
      first_name: item.first_name || "",
      last_name: item.last_name || "",
      email: item.email || "",
      role_id: item.role_id || "",
      organization_id: organizationId,
      location_ids: locationIds,
      isactive: item.isactive || false,
    };

    setFormData(updatedFormData);

    // Filter locations based on the selected organization
    if (organizationId) {
      const orgLocations =
        locations?.filter(
          (loc) =>
            loc.organization_id === organizationId ||
            loc.organization_id === parseInt(organizationId)
        ) || [];
      setFilteredLocations(orgLocations);
    } else {
      setFilteredLocations(locations);
    }

    setShowEditModal(true);
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Toggle active status
  const toggleActiveStatus = async () => {
    if (!formData.isactive)
      try {
        const response = await api.put("/user/updateActiveStatus", {
          id: editingUser.user_id, // Assuming formData has an id field for the user
        });
        if (response.data.success) {
          const updatedUser = { ...formData, isactive: !formData.isactive };
          setFormData(updatedUser);
          // Update the specific user in the list of users
          setUsers((prevUsers) =>
            prevUsers.map((user) =>
              user.user_id === editingUser.user_id ? updatedUser : user
            )
          );
        } else {
          toast.error("Failed to update active status");
        }
      } catch (error) {
        console.error("Error updating active status:", error);
        toast.error("An error occurred while updating active status");
      }
  };

  // Check if user has permission to edit organization
  const canEditOrganization = () => {
    // Super admin of org 1 or has canseeeverything permission
    if (
      (user.role === "superadmin" && user.organization_id === 1) ||
      user.canseeeverything
    ) {
      return true;
    }
    // Super admin of other org can only edit within their org
    if (user.role === "superadmin") {
      return false; // Can edit but only within their org
    }
    // Admin cannot edit organization
    return false;
  };

  // Check if user has permission to edit role
  const canEditRole = () => {
    // Super admin or admin can edit roles
    return user.role === "superadmin" || user.role === "admin";
  };

  // Check if user has permission to edit location
  const canEditLocation = () => {
    // Super admin can edit any location
    if (user.role === "superadmin") {
      return true;
    }
    // Admin can only edit locations they are a member of
    if (user.role === "admin") {
      return true; // Will filter locations in the dropdown
    }
    return false;
  };

  // Get available organizations based on user role
  const getAvailableOrganizations = () => {
    if (
      (user.role === "superadmin" && user.organization_id === 1) ||
      user.canseeeverything
    ) {
      return organizations;
    }
    // Other super admins and admins can only see their organization
    return organizations.filter((org) => org.id === user.organization_id);
  };

  // Get available locations based on user role and selected organization
  const getAvailableLocations = () => {
    // For super admin of org 1 or users with canseeeverything permission
    if (
      (user.role === "superadmin" && user.organization_id === 1) ||
      user.canseeeverything
    ) {
      return { filteredLocations };
    }

    // Super admin of other org can see all locations in their org
    if (user.role === "superadmin") {
      return filteredLocations.filter(
        (loc) => loc.organization_id === user.organization_id
      );
    }

    // Admin can only see locations they are a member of
    if (user.role === "admin") {
      return filteredLocations.filter(
        (loc) =>
          user.locations &&
          user.locations.some((userLoc) => userLoc.id === loc.id)
      );
    }

    return [];
  };

  // Get available role options based on user's role
  const getAvailableRoles = () => {
    if (user.role === "superadmin") {
      return [
        { id: "1", name: "Admin" },
        { id: "2", name: "Employee" },
        { id: "3", name: "Super Admin" },
      ];
    } else {
      return [
        { id: "1", name: "Admin" },
        { id: "2", name: "Employee" },
      ];
    }
  };

  // Handle save user changes
  const handleSaveUser = async () => {
    try {
      setLoadingSaveUser(true);

      // Validate role selection based on user permissions
      if (formData.role_id) {
        const availableRoles = getAvailableRoles().map((role) => role.id);
        if (!availableRoles.includes(String(formData.role_id))) {
          toast.error("You don't have permission to assign this role");
          setLoadingSaveUser(false);
          return;
        }
      }

      // Prepare user data for API
      const userData = {
        ...formData,
        id: editingUser.user_id, // Ensure we're using the correct ID field
        organization_id: formData.organization_id || "",
        location_ids: Array.isArray(formData.location_ids)
          ? formData.location_ids.filter(
              (id) => id !== null && id !== undefined
            )
          : [],
      };

      // Call the API to update the user
      const response = await api.patch(
        `/user/updateUserWithAccessControl`,
        userData
      );

      if (response.status === 200) {
        // Show success message
        toast.success("User updated successfully");

        // This gets the users again from the API.
        handleSearchUser();

        // Close the modal
        setShowEditModal(false);

        // Reset form data
        setFormData({});
        setEditingUser(null);
      } else {
        // Show error message
        toast.error("Failed to update user");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error(
        error.response?.data?.message ||
          "An error occurred while updating the user"
      );
    } finally {
      setLoadingSaveUser(false);
    }
  };

  // Handle delete user/invite
  const handleDelete = (item) => {
    // Set the item to delete and show confirmation dialog
    setItemToDelete(item);
    setShowConfirmDialog(true);
  };

  // Confirm delete after user confirmation
  const confirmDelete = async () => {
    try {
      if (!itemToDelete) return;

      setIsLoading(true);
      if (activeTab === "invites" && itemToDelete.id) {
        await api.delete(`/invites/deleteByInviteId/${itemToDelete.id}`);
        toast.success("Invite deleted successfully");
        // Remove the deleted invite from the local state
        setInvites(invites.filter((invite) => invite.id !== itemToDelete.id));
      } else if (activeTab === "users" && itemToDelete.user_id) {
        // Call the correct API endpoint for deleting users
        await api.delete(`/user/deleteUser/${itemToDelete.user_id}`);
        toast.success("User deleted successfully");
        // Remove the deleted user from the local state
        setUsers(users.filter((user) => user.user_id !== itemToDelete.user_id));
      } else {
        toast.error("Unable to delete: Missing ID");
      }
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error(error?.response?.data?.message || "Error deleting item");
    } finally {
      setIsLoading(false);
      setShowConfirmDialog(false);
      setItemToDelete(null);
    }
  };

  // Cancel delete
  const cancelDelete = () => {
    setShowConfirmDialog(false);
    setItemToDelete(null);
  };

  // Define columns for the users table
  const userColumns = [
    {
      key: "first_name",
      header: "First Name",
      minWidth: "10rem",
      cellClassName: "pl-5",
    },
    {
      key: "last_name",
      header: "Last Name",
      minWidth: "10rem",
    },
    {
      key: "email",
      header: "Email",
      minWidth: "16rem",
      wrap: true,
    },
    {
      key: "role_id",
      header: "Role",
      render: (row) => {
        // Map role_id (number) to role name
        const roleMap = {
          3: "Super Admin",
          2: "Employee",
          1: "Admin",
        };
        return (
          <span className="font-medium">
            {roleMap[row.role_id] || `Role ${row.role_id}`}
          </span>
        );
      },
      minWidth: "10rem",
    },
    {
      key: "organization",
      header: "Organization",
      minWidth: "12rem",
    },
    {
      key: "location_names",
      header: "Location (s)",
      render: (row) => {
        if (!row.location_names || row.location_names.length === 0)
          return "None";
        return Array.isArray(row.location_names)
          ? row.location_names.join(", ")
          : row.location_names;
      },
      minWidth: "12rem",
      wrap: true,
    },
    {
      key: "isactive",
      header: "Active Status",
      render: (row) => (
        <span className={row.isactive ? "text-green-600" : "text-red-600"}>
          {row.isactive ? "Active" : "Inactive"}
        </span>
      ),
      minWidth: "10rem",
      align: "left",
    },
    {
      key: "isappinstalled",
      header: "App Installed",
      render: (row) => (
        <span
          className={row.isappinstalled ? "text-green-600" : "text-gray-600"}
        >
          {row.isappinstalled ? "Yes" : "No"}
        </span>
      ),
      minWidth: "10rem",
      align: "left",
    },
    {
      key: "edit",
      header: "",
      render: (row) => (
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleEdit(row);
          }}
          className="px-3 py-1 bg-[#E5A50E] text-white rounded-md hover:bg-[#c48c0c]"
        >
          Edit
        </button>
      ),
      minWidth: "6rem",
      align: "center",
    },
    {
      key: "delete",
      header: "",
      render: (row) => (
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(row);
          }}
          className="px-3 py-1 bg-gray-500 text-white rounded-md hover:bg-gray-600"
        >
          Delete
        </button>
      ),
      minWidth: "6rem",
      align: "center",
    },
  ];

  // Define columns for the invites table
  const inviteColumns = [
    {
      key: "email",
      header: "Email",
      minWidth: "16rem",
      cellClassName: "px-5",
    },
    {
      key: "invite_code",
      header: "Invite Link",
      minWidth: "16rem",
      render: (row) => {
        const inviteLink = `https://wiz.halcyon-eco.com/register/${row.invite_code}`;
        return (
          <div className="flex items-center">
            <span className="truncate mr-2">{inviteLink}</span>
            <button
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(inviteLink);
                toast.success("Invite link copied to clipboard!");
              }}
              className="p-1 bg-gray-200 rounded-md hover:bg-gray-300"
              title="Copy invite link"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                />
              </svg>
            </button>
          </div>
        );
      },
    },
    {
      key: "role_name",
      header: "Role",
      render: (row) => {
        const roleMap = {
          superadmin: "Super Admin",
          admin: "Admin",
          employee: "Employee",
        };
        return (
          <span className="font-medium">
            {roleMap[row.role_name] || row.role_name}
          </span>
        );
      },
      minWidth: "10rem",
    },
    {
      key: "organization_name",
      header: "Organisation",
      minWidth: "12rem",
    },
    {
      key: "location_names",
      header: "Locations",
      render: (row) => {
        if (!row.location_names || row.location_names.length === 0)
          return "None";
        return Array.isArray(row.location_names)
          ? row.location_names.join(", ")
          : row.location_names;
      },
      minWidth: "16rem",
      wrap: true,
    },
    {
      key: "createdAt",
      header: "Created At",
      render: (row) => new Date(row.createdAt).toLocaleDateString(),
      minWidth: "10rem",
    },
    {
      key: "delete",
      header: "",
      render: (row) => (
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(row);
          }}
          className="px-3 py-1 bg-gray-500 text-white rounded-md hover:bg-gray-600"
        >
          Delete
        </button>
      ),
      minWidth: "6rem",
      align: "center",
    },
  ];

  // Add a function to handle the hamburger menu click
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  // Add a handler for Enter key in search
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchUser();
    }
  };

  useEffect(() => {
    if (formData.role_id === "3") {
      // Assuming "3" is the ID for Super Admin
      setFilteredLocations([]);
      setShowLocations(false);
      setFormData((prev) => ({
        ...prev,
        location_ids: [], // Set locations to an empty array
      }));
    } else {
      setShowLocations(true);
    }
  }, [formData.role_id]);

  return (
    <Layout user={user}>
      <div className="w-[100%] h-full sm:p-10 p-4 pt-[20px] sm:pt-[20px] xl:pt-[25px] sm:pr-[120px]">
        {/* Add hamburger menu for mobile/tablet */}
        <div className="flex items-center mb-4 xl:hidden">
          <button
            onClick={toggleDrawer}
            className="p-2 mr-4 rounded-md hover:bg-gray-200"
            aria-label="Toggle navigation menu"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
          <BreadcrumbsComponent breadCrumbsArr={breadCrumbsArr} />
        </div>

        {/* Title for desktop */}
        <div className="hidden xl:block mb-4">
          <BreadcrumbsComponent breadCrumbsArr={breadCrumbsArr} />
        </div>

        {/* Tabs */}
        <div className="flex mb-4 border-b">
          <nav className="flex space-x-8">
            <button
              onClick={() => handleTabChange("users")}
              className={`py-4 px-1 border-b-2 font-medium text-sm ${
                activeTab === "users"
                  ? "border-[#E5A50E] text-[#E5A50E]"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              }`}
            >
              Users
            </button>
            <button
              onClick={() => handleTabChange("invites")}
              className={`py-4 px-1 border-b-2 font-medium text-sm ${
                activeTab === "invites"
                  ? "border-[#E5A50E] text-[#E5A50E]"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              }`}
            >
              Invites
            </button>
          </nav>
        </div>

        {/* Users Tab Content */}
        {activeTab === "users" && (
          <div>
            <div className="flex items-center mb-6 gap-4">
              <div className="w-full max-w-md">
                <SearchBar
                  placeholder="Search by email..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </div>
              <button
                onClick={handleSearchUser}
                className="px-4 py-2 bg-[#E5A50E] text-white rounded-md hover:bg-[#c48c0c]"
                disabled={isLoading}
              >
                {isLoading ? "Searching..." : "Search"}
              </button>
            </div>
            <Table
              columns={userColumns}
              data={users}
              isLoading={isLoading}
              emptyMessage="No users found. Search for a user by email."
            />
          </div>
        )}

        {/* Invites Tab Content */}
        {activeTab === "invites" && (
          <div className="w-full mr-[75px]">
            {isLoading ? (
              <LoadingSpinner isFullPage={true} />
            ) : (
              <Table
                columns={inviteColumns}
                data={invites}
                isLoading={isLoading}
                emptyMessage="No invites found"
              />
            )}
          </div>
        )}

        {/* Confirmation Dialog */}
        {showConfirmDialog && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
              <h3 className="text-xl font-bold mb-4">Confirm Delete</h3>
              <p className="mb-6">
                Are you sure you want to delete this{" "}
                {activeTab === "users" ? "user" : "invite"}?
                {activeTab === "invites" && itemToDelete?.email && (
                  <span className="font-medium block mt-2">
                    Email: {itemToDelete.email}
                  </span>
                )}
                {activeTab === "users" && itemToDelete?.email && (
                  <span className="font-medium block mt-2">
                    User: {itemToDelete.first_name} {itemToDelete.last_name} (
                    {itemToDelete.email})
                  </span>
                )}
              </p>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={cancelDelete}
                  className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
                  disabled={isLoading}
                >
                  Cancel
                </button>
                <button
                  onClick={confirmDelete}
                  className="px-4 py-2 bg-[#E5A50E] text-white rounded-md hover:bg-[#c48c0c]"
                  disabled={isLoading}
                >
                  {isLoading ? "Deleting..." : "Delete"}
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Edit User Modal */}
        {showEditModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full">
              <h3 className="text-xl font-bold mb-4">Edit User</h3>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                {/* First Name */}
                <div>
                  <label className="block text-[15px] font-bold mb-1">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>

                {/* Last Name */}
                <div>
                  <label className="block text-[15px] font-bold mb-1">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>

                {/* Email */}
                <div>
                  <label className="block text-[15px] font-bold mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>

                {/* Role */}
                {canEditRole() && (
                  <div className="flex flex-col relative">
                    <label className="text-[15px] font-bold">
                      Role
                      <span className="text-[#F91212]">*</span>
                    </label>
                    <div className="w-full mt-2">
                      <ModalSelect
                        data={getAvailableRoles()}
                        optionDefault="Select Role"
                        formik={formik}
                        field="role_id"
                        defaultOption={
                          formData.role_id
                            ? {
                                name:
                                  formData.role_id === 3
                                    ? "Super Admin"
                                    : formData.role_id === 1
                                    ? "Admin"
                                    : formData.role_id === 2
                                    ? "Employee"
                                    : "Select Role",
                                id: String(formData.role_id),
                              }
                            : null
                        }
                      />
                    </div>
                  </div>
                )}

                {/* Organization */}
                <div className="flex flex-col relative">
                  <label className="text-[15px] font-bold">
                    Organisation
                    <span className="text-[#F91212]">*</span>
                  </label>
                  <div className="w-full mt-2">
                    <ModalSelect
                      data={organizations || []}
                      optionDefault="Organization"
                      formik={formik}
                      field="organization_id"
                      defaultOption={
                        formData.organization_id &&
                        organizations &&
                        organizations.length > 0
                          ? organizations.find(
                              (org) =>
                                org.id === formData.organization_id ||
                                org.id === parseInt(formData.organization_id)
                            ) || null
                          : null
                      }
                    />
                  </div>
                </div>

                {/* Active Status */}
                <div>
                  <label className="block text-[15px] font-bold mb-1">
                    Active Status
                  </label>
                  <div className="flex items-center mt-2">
                    <button
                      type="button"
                      onClick={toggleActiveStatus}
                      disabled={formData.isactive}
                      className={`px-4 py-2 rounded-md ${
                        formData.isactive
                          ? "bg-white text-green-500"
                          : "bg-gray-300 text-gray-700"
                      }`}
                    >
                      {formData.isactive ? "Active" : "Inactive"}
                    </button>
                  </div>
                </div>
              </div>

              {/* Locations (Multiselect) - Full width */}
              {showLocations && canEditLocation() && (
                <div className="flex flex-col relative mb-4">
                  <label className="text-[15px] font-bold">
                    Location
                    {formData.role_id !== "3" ? (
                      <span className="text-[#F91212]"> *</span>
                    ) : (
                      <span className="text-[#787878]"> (optional)</span>
                    )}
                  </label>
                  <div className="w-full mt-2">
                    <ModalSelectWithCheckBox
                      data={filteredLocations || []}
                      optionDefault="Location"
                      formik={formik}
                      field="location_ids"
                      defaultValues={formData.location_ids}
                    />
                  </div>
                </div>
              )}

              <div className="flex justify-end space-x-3 mt-6">
                <button
                  onClick={() => setShowEditModal(false)}
                  className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
                  disabled={loadingSaveUser}
                >
                  Cancel
                </button>
                <button
                  onClick={handleSaveUser}
                  className="px-4 py-2 bg-[#E5A50E] text-white rounded-md hover:bg-[#c48c0c]"
                  disabled={loadingSaveUser}
                >
                  {loadingSaveUser ? "Saving..." : "Save Changes"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Users;
