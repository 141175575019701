import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const MenuItem = ({ icon, title, path, isActive, onClick }) => (
  <div
    onClick={onClick}
    className={`cursor-pointer flex items-center relative py-2 mt-2 text-lg font-medium rounded-lg ${
      isActive
        ? "text-gray-900 hover:bg-gray-200 focus:bg-gray-200"
        : "text-[#8597A8] hover:text-gray-900 focus:text-gray-900"
    }`}
  >
    <img src={icon} alt="" className="h-[32px] w-8" />
    <span className="ml-2">{title}</span>
    {isActive ? (
      <img
        src="/images/Group 1 (1).svg"
        alt=""
        className="absolute -right-10"
      />
    ) : null}
  </div>
);

const Navbar = ({ isOpen, navItems, handleToggler, user }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    // Clear all localStorage items
    localStorage.clear();
    // Navigate to home page
    navigate("/");
    // Close the navbar if it's open
    handleToggler && handleToggler();
  };

  return (
    <nav
      className={`md:flex flex-col h-full justify-between md:pb-0 md:min-h-[100vh]  ${
        isOpen ? "flex" : "hidden"
      }`}
    >
      <div className="flex flex-col gap-12">
        <div>
          <img
            src="/images/nav-logo (3) 1.png"
            alt=""
            className="w-[154px] h-[90px] mt-[18px]"
          />
        </div>
        <div className="flex flex-col items-center gap-5">
          <div
            className="rounded-full relative"
            style={{ width: "fit-content" }}
          >
            <img
              className="rounded-full border-4 border-white"
              src="/images/adminImage.png"
              alt="user"
            />
            <img
              className="rounded-full border-4 border-white absolute top-0 scale-[1.1]"
              src="/images/ellipse.svg"
              alt="user"
            />
          </div>
          <div className="flex flex-col items-center gap-1.5">
            {user && (
              <>
                <h4 className="text-xl font-bold text-center">
                  {user.first_name + " " + user.last_name}
                </h4>
                <p className="text-xs font-normal text-[#787878] text-center">
                  {user.email}
                </p>
              </>
            )}
          </div>
        </div>
        <div>
          {navItems.map((item) => (
            <MenuItem
              key={item.path}
              title={item.title}
              path={item.path}
              icon={item.icon}
              isActive={
                location.pathname.split("/").filter(Boolean)[0] ===
                item.pathActive
              }
              onClick={() => {
                navigate(item.path);
                handleToggler && handleToggler();
              }}
            />
          ))}
        </div>
      </div>
      <div
        onClick={handleLogout}
        className={`cursor-pointer flex items-center py-2 mt-9 text-lg font-medium text-[#D36363] rounded-lg hover:bg-gray-200 transform translate-y-[-30px] lg:translate-y-[-30px] md:translate-y-[-60px] `}
      >
        <img src="/images/logout-icon.svg" alt="" className="h-[32px] w-8" />
        <span className="ml-2 ">Logout</span>
      </div>
    </nav>
  );
};

export default Navbar;
