import { useEffect } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useUserStore } from "../../storesZustand/useUserStore";
import { refreshTokens } from "../../utils/api";

// use redux for protection insted of localstorage

export async function isAuthenticated() {
  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");

  if (!token) {
    localStorage.clear();
    return false;
  }

  try {
    // Check if the token is expired
    const decodedToken = jwt_decode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      // Token is expired, try to refresh
      if (refreshToken) {
        try {
          const newToken = await refreshTokens(refreshToken);
          if (newToken) {
            return true;
          }
        } catch (error) {
          console.error("Failed to refresh token:", error);
        }
      }
      localStorage.clear();
      return false;
    }

    return true;
  } catch (error) {
    console.error("Error checking token:", error);
    localStorage.clear();
    return false;
  }
}

export const ProtectedRouteMiddleware = () => {
  const [user, setUsersDataAfterLogin] = useUserStore((state) => [
    state.user,
    state.setUsersDataAfterLogin,
  ]);
  const role = user?.role;
  const location = useLocation();

  if (!isAuthenticated()) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // Redirect to verify-user if the user is not active
  if (user && user?.active === false) {
    return <Navigate to="/verifycode" state={{ email: user.email }} replace />;
  }

  const protectedPaths = [
    "/dashboard/organizations",
    "organizations/:organizationId",
    "/dashboard/locations",
    "/organizations/:organizationId/locations/:locationId",
    "/training",
    "/savings",
    "/usage",
  ];

  if (
    role === "employee" &&
    protectedPaths.some((path) => window.location.pathname === path)
  ) {
    return <Navigate to="/" replace />;
  }

  if (role === "admin" && location.pathname === "/training") {
    return <Navigate to={`/training/org/${user.organization_id}`} replace />;
  }

  return <Outlet />;
};

// export const ProtectedRouteMiddlewareSuperAdmin = () => {
//   const token = localStorage.getItem('token');
//   const [user] = useUserStore((state) => [state.user]);
//   const role = user?.role;
//   const location = useLocation();
//
//   if (!token || !isAuthenticated()) {
//     localStorage.clear();
//     return <Navigate to='/' replace />;
//   }
//
//   if (role === 'admin' && location.pathname === '/training') {
//     return <Navigate to={`/training/org/${user.organizationId}`} replace />;
//   }
//
//   if (role === 'admin') {
//     return <Navigate to='/training' replace />;
//   }
//
//   return <Outlet />;
// };

export const ProtectedRouteMiddlewareSuperAdminHalcyonEco = () => {
  const [user] = useUserStore((state) => [state.user]);
  const role = user?.role;
  const location = useLocation();

  console.log("role", role);
  console.log({ user });
  console.log(isAuthenticated());
  if (!isAuthenticated()) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // Special handling for /usage route
  if (location.pathname === "/usage") {
    // Only allow access if user is a superadmin of org 1
    if (role === "superadmin") {
      return <Outlet />;
    }
  }

  // Regular route handling
  if (role === "superadmin" && user.organization_id !== 1) {
    return <Navigate to="/dashboard/locations" replace />;
  }

  if (role === "employee") {
    return <Navigate to="/training-portal" replace />;
  }

  if (role === "admin") {
    return <Navigate to={`/training/org/${user.organization_id}`} replace />;
  }

  return <Outlet />;
};

export const PublicRouteMiddleware = () => {
  const token = localStorage.getItem("token");
  const [user] = useUserStore((state) => [state.user]);

  useEffect(() => {
    if (token) {
      // Create a new script element
      const script = document.createElement("script");
      script.src = "//code.tidio.co/z9magf4whubw9ruamgzzb7djulsekkij.js";
      script.async = true;
      // Find the body element and insert the script just before the </body> tag
      document.body.appendChild(script);
    }
  }, [token]);

  if (!token || !isAuthenticated()) {
    localStorage.clear();
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};
